import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from  "../../static/img/main/logo.png";

function Nav() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(null);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const toggleMenu = (index) => {
        if (openMenu === index) {
          setOpenMenu(null);
        } else {
          setOpenMenu(index);
        }
      };

    // 햄버거 아이콘을 클릭하면 모바일 메뉴를 토글합니다.
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
       <>
        <div className='pc'>
            <div className='pc_navi'>
                <nav>
                    <div className="col_group">
                        <div className="col_group_left">
                            <div className="logo_img">
                                <Link to="/">
                                    <img src={logo} alt="로고"/>
                                </Link>
                            </div>
                        </div>
                        <div className="col_group_center">  
                            <ul className="dropdown">
                                <li className="dropdown-button">
                                    <Link to="/introduce" className="gallery_tit"> {/* Link 컴포넌트 사용 */}
                                        소개
                                    </Link>
                                </li>
                                <ul className="dropdown_1">
                                    <li className="dropdown-button">
                                        <Link to="/visitTip" className="gallery_tit">
                                            관람안내
                                        </Link>
                                         <span className="dropdown-content">
                                            <Link to="/visitTip" className="tit">방문TIP</Link>
                                            <Link to="/brandList" className="tit">참가브랜드리스트</Link>
                                            <Link to="/visit" className="tit">오시는길</Link>
                                        </span>
                                    </li>
                                </ul>
                                <ul className="dropdown_2">
                                    <li className="dropdown-button">
                                        <Link to="/payBack" className="gallery_tit">베세페 이벤트</Link>
                                         <span className={`dropdown-content ${isSubMenuOpen ? 'open' : ''}`}>
                                                <Link to="/payBack" className="tit">박람회 이벤트 / 페이백 안내</Link>
                                                <Link to="/brandEvent" className="tit">참가브랜드 이벤트</Link>
                                        </span>
                                    </li>
                                </ul>
                                <ul className="dropdown_3">
                                    <li className="dropdown-button">
                                        <Link to="/notice" className="gallery_tit">공지사항</Link>
                                         <span className={`dropdown-content ${isSubMenuOpen ? 'open' : ''}`}>
                                            <Link to="/notice" className="tit">공지사항</Link>
                                            <Link to="/brandApply" className="tit">업체 참가 문의</Link>
                                        </span> 
                                    </li>
                                </ul>
                                <ul className="dropdown">
                                    <li className="dropdown-button last_navi">
                                        <Link to="/member" className="gallery_tit">사전등록</Link>
                                    </li>
                                </ul>
                            </ul>
                        </div> 
                        <div className="col_group_right"></div>
                    </div>
                </nav>
            </div>
           
        </div>

        <div className='mobile'>
            <div className={`mobile_navi ${isMobileMenuOpen ? 'open' : ''}`}>
                <nav>
                    <div className="col_group">
                        <div className="col_group_left">
                        {/* 햄버거 아이콘 */}
                        <div className="hamburger" onClick={toggleMobileMenu}>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                        {/* 모바일 메뉴 */}
                        <div className={`dropdown ${isMobileMenuOpen ? 'open' : ''}`}>
                                    <ul className="dropdown-button">
                                       <li>
                                       <Link to="/introduce"> {/* Link 컴포넌트 사용 */}
                                        소개
                                        </Link>
                                       </li>
                                    </ul>
                                    <ul className="dropdown_1">
                                        <li className="dropdown-button">
                                        <span onClick={() => toggleMenu(1)} className="gallery_tit">
                                            관람안내
                                        </span>
                                        {openMenu === 1 && (
                                        <span className={`dropdown-content ${openMenu ? 'open' : ''}`}>
                                            <Link to="/visitTip" className="tit">방문TIP</Link>
                                            <Link to="/brandList" className="tit">참가브랜드리스트</Link>
                                            <Link to="/visit" className="tit">오시는길</Link>
                                        </span>
                                         )}
                                        </li>
                                        
                                    </ul>
                                    <ul className="dropdown_2">
                                        <li className="dropdown-button">
                                            <span onClick={() => toggleMenu(2)}  className="gallery_tit">
                                                베세페 이벤트
                                            </span>
                                            {openMenu === 2 && (
                                             <span className={`dropdown-content ${openMenu ? 'open' : ''}`}>
                                                <Link to="/payBack" className="tit">박람회 이벤트 / 페이백 안내</Link>
                                                <Link to="/brandEvent" className="tit">참가브랜드 이벤트</Link>
                                            </span>
                                             )}
                                        </li>
                                    </ul>
                                    <ul className="dropdown_3">
                                        <li className="dropdown-button">
                                            <span onClick={() => toggleMenu(3)}  className="gallery_tit">
                                           공지사항
                                            </span>
                                            {openMenu === 3 && (
                                             <span className={`dropdown-content ${openMenu ? 'open' : ''}`}>
                                                <Link to="/notice" className="tit">공지사항</Link>
                                                <Link to="/brandApply" className="tit">업체 참가 문의</Link>
                                            </span> 
                                             )}
                                        </li>
                                    </ul>
                                    <ul className="">
                                        <li className="dropdown-button">
                                            <Link to="/member" className="gallery_tit">사전등록</Link>
                                        </li>
                                    </ul>
                        </div>
                        </div>
                        <div className="col_group_center">
                        <div className="logo_img">
                            <a href="/">
                            <img src={logo} alt="로고" />
                            </a>
                        </div>
                        </div>
                        <div className="col_group_right"></div>
                    </div>
                </nav>
            </div>
        </div>
       </>
    );
}

export default Nav;
