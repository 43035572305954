import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Main from './pages/Main';
import Member from './pages/Member';
import MemberFlexble from './pages/MemberFlexble';
import Introduce from './pages/Introduce';
import VisitTip from './pages/VisitTip';
import BrandList from './pages/BrandList';
import Visit from './pages/Visit';
import PayBack from './pages/PayBack';
import BoardDetail from './pages/BoardDetail';
import BrandEvent from './pages/BrandEvent';
import BrandEventDetail from './pages/BrandEventDetail';
import Notice from './pages/notice';
import NoticeDetail from './pages/noticeDetail';
import BrandApply from './pages/BrandApply';
import Personal from './pages/Personal.js';
import UsedRules from './pages/usedRules';
import SelectPlace from './pages/SelectPlace';
import YuramAdmin from './pages/YuramAdmin';
import ShowMemberCount from './pages/ShowMemberCount';
import MemberH from './pages/MemberHasong.js';
import MemberGangju from './pages/MemberGangju.js';
import FairCreate from './pages/FairCreate.js';
import MemberSungNam from './pages/MemberSungNam.js';
import MemberGo from './pages/MemberGo.js';
import BrandListDetail from './pages/BrandListDetail.js';
import Logout from './components/Common/Logout.js';
import { useCookies } from "react-cookie";
import BrandListExample from './pages/BrandListExample.js';
import MemberSun from './pages/Membersun.js';
import Memberpyung from './pages/Memberpyung.js';
import MemberDeagu from './pages/MemberDeagu.js';
import MemberNamyoung from './pages/MemberNamyoung.js';
import MemberPoHang from './pages/MemberPoHang.js';
import MemberSeoul from './pages/MemberSeoul.js'; // 서울
import MemberNaJu from './pages/MemberNaJu.js'; // 나주
import MemberUlSan from './pages/MemberUlSan.js'; // 울산
import MemberInCheon from './pages/MemberInCheon.js'; // 인천

function App() {
    // 로그인 상태를 관리하는 상태 변수
    const isUserLoggedIn = localStorage.getItem('loggedIn');

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SelectPlace />} />
                <Route path="/ShowMemberCount" element={<ShowMemberCount />} />
                <Route path="/yuramAdmin" element={<YuramAdmin />} />
                <Route path="/main" element={<Main />} />
                <Route path="/member" element={<Member />} />
                {/* 동적라우팅 */}
                <Route path="/member/:cityunique" element={<MemberFlexble />} />
                <Route path="/introduce" element={<Introduce />} />
                <Route path="/visitTip" element={<VisitTip />} />
                <Route path="/brandList" element={<BrandList />} />
                <Route path="/brandListExample" element={<BrandListExample />} />
                <Route path="/visit" element={<Visit />} />
                <Route path="/payBack" element={<PayBack />} />
                <Route path="/brandEvent" element={<BrandEvent />} />
                <Route path="/notice" element={<Notice />} />
                <Route path="/noticeDetail/:id" element={<NoticeDetail />} />
                <Route path="/boardDetail/:id" element={<BoardDetail />} />
                <Route path="/brandListDetail/:id" element={<BrandListDetail />} />
                <Route path="/brandEventDetail/:id" element={<BrandEventDetail />} />
                <Route path="/brandApply" element={<BrandApply />} />
                <Route path="/personal" element={<Personal />} />
                <Route path="/usedRules" element={<UsedRules/>} />
                <Route path="/memberH" element={<MemberH/>} />
                <Route path="/memberG" element={<MemberGangju/>} />
                <Route path="/memberS" element={<MemberSungNam/>} />
                <Route path="/memberGo" element={<MemberGo/>} />
                <Route path="/memberSun" element={<MemberSun/>} />
                <Route path="/fairCreate" element={<FairCreate/>} />
                <Route path="/namyoung" element={<MemberNamyoung/>} />
                <Route path="/pohang" element={<MemberPoHang/>} />
                <Route path="/Seoul" element={<MemberSeoul/>} />
                <Route path="/NaJu" element={<MemberNaJu/>} />
                <Route path="/memberDeagu" element={<MemberDeagu/>} />
                <Route path="/UlSan" element={<MemberUlSan/>} />
                <Route path="/memberpyung" element={<Memberpyung/>} />
                <Route path="/InCheon" element={<MemberInCheon/>} />


            </Routes>
                {isUserLoggedIn ? (
                <Logout/>
                ) : null}
        </BrowserRouter>
    );
}

export default App;
