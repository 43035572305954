//성민퍼블 컨텐츠 필요
import React, { useEffect, useState } from 'react';
//const { kakao } = window;
import { Map, MapMarker} from "react-kakao-maps-sdk";

function Location({city,setCity, latitude, longitude, address}) {
  let lat;
  let lng;
  let text;
  let src;
    if(city == '메세유람_사전등록_수원'){
      lat = '37.279955848210236';
      lng = '127.04547029415372';
      text = '아주대학교 체육관';
      src= `https://map.kakao.com/link/map/${text},${lat},${lng}`;
    }else if(city == '메세유람_사전등록_서울'){
      lat = '37.48434373532856';
      lng = '126.89665202030233';
      text = '엘컨벤션';
      src= `https://map.kakao.com/link/map/${text},${lat},${lng}`;
    }else if(city == '메세유람_사전등록_화성'){
      lat = '37.213987708644716';
      lng = '126.94806595096587';
      text = '장안대학교 컨벤션홀 자아실현관';
      src= `https://map.kakao.com/link/map/${text},${lat},${lng}`;
    }
    else if(city == '메세유람_사전등록_광주'){
      lat = '35.135200';
      lng = '126.877339';
      text = '광주광역시염주종합체육관빛고을체육관';
      src= `https://map.kakao.com/link/map/${text},${lat},${lng}`;
    }
    else if(city == '메세유람_사전등록_고양'){
      lat = '37.653796';
      lng = '126.768861';
      text = '고양 꽃 전시관 실내 2전시장';
      src= `https://map.kakao.com/link/map/${text},${lat},${lng}`;
    }
    else if(city == '메세유람_사전등록_순천'){
      lat = '34.966627';
      lng = '127.483918';
      text = '순천대학교 국제문화컨벤션관';
      src= `https://map.kakao.com/link/map/${text},${lat},${lng}`;
    }
    else if(city == '메세유람_사전등록_성남'){
      lat = '37.45753125853082';
      lng = '127.12920805708791';
      text = '동서울대학교 체육관';
      src= `https://map.kakao.com/link/map/${text},${lat},${lng}`;
    }
    else if(city == '메세유람_사전등록_평택'){
      lat = '37.06597820343464';
      lng = '127.08203489761284';
      text = '국제대학교 창조관';
      src= `https://map.kakao.com/link/map/${text},${lat},${lng}`;
    }
    else if(city == '메세유람_사전등록_안양'){
      lat = '37.37950091867396';
      lng = '126.92959595102492';
      text = '성결대학교 체육관';
      src= `https://map.kakao.com/link/map/${text},${lat},${lng}`;
    }
    else if(city == '메세유람_사전등록_대구'){
      lat = '35.86111415971008';
      lng = '128.49145730247122';
      text = '계명문화대학교체육관';
      src= `https://map.kakao.com/link/map/${text},${lat},${lng}`;
    }else{
      lat = latitude;
      lng = longitude;
      text = address;
      src= `https://map.kakao.com/link/map/${text},${lat},${lng}`;
    }

    if(latitude !== "" && longitude !== ""){
      lat = longitude; //데이터 올때 반대로 와서 일단 이렇게 함
      lng = latitude;
      text = address;
      src= `https://map.kakao.com/link/map/${text},${lat},${lng}`;
    }
    return (
        <Map // 지도를 표시할 Container
        id="map"
        center={{
          // 지도의 중심좌표
          lat: lat,
          lng: lng,
        }}
        style={{
          // 지도의 크기
          width: "100%",
          height: "350px",
        }}
        level={3} // 지도의 확대 레벨
      >
       <MapMarker // 마커를 생성합니다
          position={{
            // 마커가 표시될 위치입니다
            lat: lat,
            lng: lng,
          }}
          className="mapMarker"
        >
            <div className="location_marker" >
                <div style={{ marginbottom: "5px", fontSize: "12px"}}>{text}</div>
                <a
                  href={src}
                  style={{ color: "#01C2D1" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  큰지도보기
                </a>
                <a
                  href={src}
                  style={{ color: "#01C2D1", marginLeft: "10px" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  길찾기
                </a>
            </div>
      </MapMarker>
      </Map>
      )
}

export default Location;