import React from 'react';
import { Link } from 'react-router-dom';
import arrow from "../static/img/arrow.svg";

import "../static/css/common.css";
import "../static/css/selectPlace.css";

const ListItem = ({ index, handleLinkClick,code, name, startDate, endDate , color }) => {
  const handleClick = () => {
    handleLinkClick(code);
    window.location.reload();
  };

  return (
    <li onClick={handleClick}>
      <Link to="/member">
        <div className='Link_click'>
          <div className={`Link_title color_${index}`}>
            { name.length ===2 ? name[0]+ " " + name[1] : name  }
            </div>
          <div className='Link_date'>{startDate} - {endDate}</div>
          <div className={`Link_icon color_${index}`}><img src={arrow} alt="Arrow"/></div>
        </div>
      </Link>
    </li>
  );
};

export default ListItem;
