import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DaumPostcode from 'react-daum-postcode';
import "../static/css/common.css";
import MiniNavi from "../components/Common/MiniNavi"
import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";
import Location from "../components/Common/Location.js"
import { scrollToTop } from '../components/Common/TotheTop.js';
import {getCookie} from '../util/cookies.js';
const { kakao } = window;

function Visit() {
    //관리자 로그인
    const jwtValue = getCookie('loginToken');
    const isUserLoggedIn = localStorage.getItem('loggedIn');
    const [city, setCity] = useState(localStorage.getItem('selectedCity') || '');
    //수정모드
    const [setting, setSetting] = useState(false);
    // 오시는길에 생성/수정할때 쓸 변수 선언
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [addressBuilding, setAddressBuilding] = useState('');

    const [visible, setVisible] = useState(false); 
    const [isItem, setIsItem] = useState(false); 

    const [data, setData] = useState(null); 
    const [showaddress, setshowAddress] = useState('');
    const [showName, setshowName] = useState('');

    const [showLat, setShowLat] = useState('');
    const [showLon, setShowLon] = useState('');

    const navigator = useNavigate();

    const handleInputChange = (event) => {
      setAddress(event.target.value);
    };
  
    const handleReset = () => {
      setAddress('');
    };

    const navi_name = "관람안내";
    const navi_name_detail = "오시는길";

    useEffect(() => {
      scrollToTop();

       //생성일 
       function toLocalISOString(date) {
        const off = date.getTimezoneOffset();
        const absoff = Math.abs(off);
        return new Date(date.getTime() - off * 60 * 1000).toISOString().slice(0, -1) + (off > 0 ? '-' : '+') + (absoff / 60).toString().padStart(2, '0') + ':' + (absoff % 60).toString().padStart(2, '0');
      }
      const date = new Date();
      const formatedate = toLocalISOString(date);
      setCreatedAt(formatedate)
      // 들어오자마자 오시는길 data 받기
      if(city){
        fetch(`https://yuram.azurewebsites.net/api/directions/${city}`, {
          method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
        .then(response => response.json())
        .then(data => {
          if( data ){
              setData(data);
              setshowName(data.name)
              setshowAddress(data.address)
              setShowLat(data.latitude)
              setShowLon(data.longitude)
              if(data.name){
                setIsItem(true)
              }
          }
        })
        .catch(error => console.error('Error:', error));
      }
      
    },[]);

    function onSetting(){
      setSetting(true)
      if (!setting) {
        setSetting(true)
      }else{
        setSetting(false)
      }
    }
    
    function visit_create(){
      const postData = {};

      if (city) postData.code = city;
      if (latitude) postData.latitude = latitude;
      if (longitude) postData.longitude = longitude;
      if (name) postData.name = name;
      if (address || addressBuilding) postData.address = address + addressBuilding;
      if (createdAt) postData.createdAt = createdAt;

      if(isItem){
        fetch(`https://yuram.azurewebsites.net/api/directions/${city}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtValue}`
          },
          body: JSON.stringify(postData)
        })
        .then(response => response.json())
        .then(data => {
          if(data.statusCode == 401){
            alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
            navigator("/yuramAdmin")
          }else{
            //console.log(data)
            window.location.reload();
          }
        })
        .catch(error => console.error('Error:', error));
      }else{
        fetch('https://yuram.azurewebsites.net/api/directions/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtValue}`
          },
          body: JSON.stringify(postData)
        })
        .then(response => response.json())
        .then(data => {
          if(data.statusCode == 401){
            alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
            navigator("/yuramAdmin")
          }else{
            window.location.reload();
          }

        })
        .catch(error => console.error('Error:', error));
      }
      }
      

     // 주소창 style
    const [addressStyle, setAddressStyle] = useState({
      maxWidth: '500px',
      border: '1px solid #ccc',
      position: 'absolute', // Change this value according to your needs
      borderRadius: '4px',
      padding: '10px',
      backgroundColor: '#fff',
      color: '#000',
      fontFamily: 'Arial, sans-serif',
      fontSize: '14px',
      fontWeight: 'normal',
      zIndex: "30",
    });

    const getAddressCoords = (address) => {
      return new Promise((resolve, reject) => {
        const geoCoder = new window.kakao.maps.services.Geocoder();

        geoCoder.addressSearch(address, (result, status) => {
          if (status === window.kakao.maps.services.Status.OK) {
            const coords = new window.kakao.maps.LatLng(result[0].x, result[0].y);
            resolve(coords);
          } else {
            reject(status);
          }
        });
      });
    };
    // 검색이 끝났을때 동작
    const handleComplete = (data) => {
      console.log(data)
      const addressValue = data.address;
      const buildingName = data.buildingName;
      getAddressCoords(addressValue)
      .then((coords) => {
        // 위도와 경도 정보를 가져온 후 원하는 작업 수행
        console.log('위도:', coords.getLat());
        console.log('경도:', coords.getLng());
        setLatitude(coords.getLat())
        setLongitude(coords.getLng())
        setAddressBuilding(buildingName)
      })
      .catch((error) => {
        console.error('Error:', error);
      });

      setVisible(false);
      setAddress(addressValue);
    };
    return (
      <>
      <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
           <MiniNavi navi_name={navi_name}/>
           
           <div className='detail_cont'>
                <div className='detail_tit'>{navi_name_detail}</div>
                <div>
                    <Location city={city} setCity={setCity} latitude ={showLat} longitude ={showLon} address = {showaddress}/>
                   <div className='visit_table_wrap'>
                            {isUserLoggedIn?(
                                                <div className='button_wrap'>
                                                  <button type='button' onClick={setting ? ()=> visit_create() : ()=> onSetting()}>
                                                      { setting  ?(
                                                        <>
                                                          저장
                                                        </>
                                                        ):(
                                                          <>
                                                          수정
                                                          </>
                                                        )}
                                                  </button>
                                                </div>
                                              ):(
                                                null
                                              )
                            }
                   { setting  ?(
                          <>
                              <div>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>상호입력</td>
                                      <td><input type="text" defaultValue={data ? data.name : null} onChange={(e)=>setName(e.target.value)} style={{width:"90%"}}></input></td>
                                    </tr>
                                    <tr>
                                      <td>주소입력</td>
                                      <td>
                                        <div className="flex" style={{justifyContent:"center"}}>
                                          <input type="hidden" name="place" />
                                          <input
                                            type="text"
                                            size="40"
                                            name="address"
                                            id="address"
                                            className="essential"
                                            value={address}
                                            onChange={handleInputChange}
                                            readOnly
                                            style={{"background" : "#f1f1f1"}}
                                          />
                                          <input
                                            type="hidden"
                                            size="40"
                                            name="addressBuilding"
                                            id="addressBuilding"
                                            className="essential"
                                            readOnly
                                            value={addressBuilding}
                                          />
                                          <input type="hidden" name="lat" placeholder="위도" value={latitude} onChange={(e)=>setLatitude(e.target.value)}/>
                                          <input type="hidden" name="lng" placeholder="경도" value={longitude} onChange={(e)=>setLongitude(e.target.value)}/>
                                            <button onClick={() => setVisible(true)} type='button'>주소검색</button>
                                          <button type="button" id="reset"  onClick={handleReset}>
                                            초기화
                                          </button>
                                        </div>
                                      <div className="regi_div">
                                        <div className='flex'>
                                          {visible &&
                                            <div style={addressStyle}>
                                              <button title="닫기" onClick={() => setVisible(false)}>닫기</button>
                                              <DaumPostcode
                                                onComplete={handleComplete}
                                              />
                                            </div>
                                          }
                                        </div>
                                      </div>
                                      </td>
                                    </tr>
                                   
                                  </tbody>
                                </table>
                              </div>
                          </>
                          ):(
                          <>
                          </>
                    )}
                    <table>
                       <tbody>
                       <tr>
                            <td>상호</td>
                            <td> 
                            {showName}
                            </td>
                        </tr>
                        <tr>
                            <td>주소</td>
                            <td>
                            {showaddress}
                            </td>
                        </tr>
                       </tbody>
                    </table>
                   </div>
                </div>
           </div>
        </main>
      </div>
     <Footer city={city} setCity={setCity} />
      </>
    );
}

export default Visit;