import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid'


const FairUpdateModal = ({
    closeUpdateModal,
    fairIdUpdate,
    fairNameUpdate,
    fairCodeUpdate,
    fairShowUpdate,
    fairStartUpdate,
    fairEndUpdate,
    bannerImageUpdate,
    showUpdateModal,
    parameterUpdate,
    fairBannerUrlUpdate,

    handleFairNameChangeUpdate,
    handleCodeFairChangeUpdate,
    handleShowFairChange,
    handleFairStartChangeUpdate,
    handleFairEndChangeUpdate,
    handleFileChangeUpdate,
    handleShowFairChangeUpdate,
    handleDelete,
    parameter,
    fairUpdate,
    deleteImg,
    setFairBannerUrlUpdate,
}) => {
   
    const [bannerImage , setBannerImage] = useState();
    const [loading, setLoading] = useState(false);
    
    function formatDate(dateString) {
        const startDate = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short', hour: '2-digit', minute: '2-digit' };
      
        const formattedDate = startDate.toLocaleString('ko-KR', options)
          .replace(/,/, ''); // 쉼표 제거
      
        return formattedDate;
      }
    
// 이미지파일 삭제 시 호출되는 함수
const handleUpdateDelete = () => {
    setBannerImage();
};


    const startDate_show = formatDate(fairStartUpdate);
    const startDate = new Date(fairStartUpdate).toISOString().slice(0, -1);

    const endDate_show = formatDate(fairEndUpdate);
    const endDate = new Date(fairEndUpdate).toISOString().slice(0, -1);

    function handleFairUrlChangeUpdate(event) {
        const imgUrl = event.target.value;
        setFairBannerUrlUpdate(imgUrl);
        }
    return (

        
        <div className='modal'>
        <div className='modal-content2'>
            <span className='close' onClick={closeUpdateModal}>&times;</span>
            <div className='fair_setting_div'>
            <table>
                <tbody>
                    <tr>
                        <td className='fair_setting_tit'>박람회 이름</td>
                        <td><input type='text' value={fairNameUpdate} onChange={handleFairNameChangeUpdate} placeholder='ex) 서울'></input></td>
                    </tr>
                    <tr>
                        <td className='fair_setting_tit'>박람회 코드</td>
                        <td><input type='text' value={fairCodeUpdate} onChange={handleCodeFairChangeUpdate} readOnly ></input></td>
                    </tr>
                    <tr>
                        <td className='fair_setting_tit'>박람회 노출 여부</td>
                        <td>
                        <label>
                        <input
                            className='margin_bottom_10'
                            type="radio"
                            name='showFair'
                            value="노출"
                            onClick={()=>handleShowFairChangeUpdate("노출")}
                            checked={fairShowUpdate === '노출'} // 조건에 따라 선택 상태를 제어
                        />
                            노출
                        </label>
                        <label>
                            <input type="radio" name='showFair' value="미노출" onClick={()=>handleShowFairChangeUpdate("미노출")} checked={fairShowUpdate === '미노출'} />
                            미노출
                        </label>
                        </td>
                    </tr>
                    <tr>
                        <td className='fair_setting_tit'>시작일</td>
                        <td>
                            {startDate_show} <br/>
                            <input className="datetime" type='date' defaultValue={startDate}   onChange={handleFairStartChangeUpdate} ></input>
                            
                        </td>
                    </tr>
                    <tr>
                        <td className='fair_setting_tit'>종료일</td>
                        <td>
                            {endDate_show} <br/>
                            <input className="datetime" type='date' defaultValue={endDate}  onChange={handleFairEndChangeUpdate} ></input>
                            </td>
                    </tr>
                    <tr>
                        <td className='fair_setting_tit'>노출배너이미지</td>
                        <td>
                            <input className='margin_bottom_10' type='file' onChange={handleFileChangeUpdate} />
                            {loading ? (
                                <p>Loading...</p>
                            ) : bannerImageUpdate ? (
                                <div className='upload_img_wrap'>
                                    <img src={URL.createObjectURL(bannerImageUpdate)} alt="Banner Preview" style={{ width: '100px', height: 'auto' }} />
                                    {/* 파일 삭제 버튼 */}
                                    <button className="delete_btn" type="button" onClick={handleDelete}>삭제</button>
                                </div>
                            ) : null}
                        </td>
                    </tr>
                    <tr>
                        <td className='fair_setting_tit'>팝업 URL</td>
                        <td>
                        <input type='text' defaultValue={fairBannerUrlUpdate} onChange={handleFairUrlChangeUpdate} ></input>
                        </td>
                    </tr>
                    <tr>
                        <td><input type='hidden' defaultValue={parameterUpdate}></input></td>
                    </tr>
                </tbody>
            </table>
        <button className="create_btn" type='button' onClick={fairUpdate}>박람회 수정</button>
    </div>

        </div>
        </div>
    );
};

export default FairUpdateModal;
