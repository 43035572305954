import React, { useState, useEffect } from 'react';
import { Link , useNavigate} from 'react-router-dom';
import {removeCookie} from '../../util/cookies.js';

function Logout({city,setCity}) {
    
const navigator = useNavigate();

const handleLogout = () => {
    // 로그아웃 로직
    localStorage.clear(); // 모든 로컬 스토리지 삭제
    // 다른 로그아웃 관련 동작 추가 가능
    navigator('/');
    window.location.reload();
};
  const [selectedCity, setSelectedCity] = useState(city);
   // 도시를 선택한 경우
   const handleCitySelect = (city) => {
    setSelectedCity(city);
    setCity(city);
    // localStorage에 선택한 도시 정보 업데이트
    localStorage.setItem('selectedCity', city);
    removeCookie('loginToken');
  };


  return (
    <>
    <div className='fixed_logout_btn_wrap'>
      <div className='fixed_logout_btn'><Link to="/ShowMemberCount">통계보기</Link></div>
      <div className='fixed_logout_btn'><Link to="/fairCreate">박람회설정</Link></div>
      <div className='fixed_logout_btn' onClick={handleLogout}><a href="javascript:void(0)">관리자 로그아웃</a></div>
    </div>
    </>
    
  );
}

export default Logout;
