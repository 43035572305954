//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import "../static/css/common.css";
import MiniNavi from "../components/Common/MiniNavi"
import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";
import { scrollToTop } from '../components/Common/TotheTop.js';

function Personal() {
 const [city, setCity] = useState(localStorage.getItem('selectedCity') || '');
    const navi_name = "약관";
    const navi_name_detail = "개인정보처리방침";
    useEffect(() => {
      scrollToTop();
    },[]);
    return (
      <>
      <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
           <MiniNavi navi_name={navi_name}/>
           
           <div className='detail_cont'>
                <div className='detail_tit'>{navi_name_detail}</div>
                <div className="privacy-policy" >
                <p>
                    주식회사 ㈜럽맘(이하 "회사)가 운영하는 통합회원 패밀리 서비스(이하 "사이트")에서는 이용자의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 등 개인 정보와 관련된 법령 상의 개인정보보호규정 및 방송통신위원회가 제정한 "개인정보보호지침"을 준수하고 있으며,
                </p>
                <p>아래와 같이 개인정보 처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.</p>
                <br />
                <p>개인정보 처리방침의 목차는 아래와 같습니다.</p>
                <br />
                <ul>
                    <li>1. 개인 정보의 수집 및 이용 목적</li>
                    <li>2. 수집하는 개인 정보 항목 및 수집방법</li>
                    <li>3. 수집하는 개인정보의 보유 및 이용기간</li>
                    <li>4. 개인 정보의 파기절차 및 방법</li>
                    <li>5. 개인정보 제3자 동의</li>
                    <li>6. 개인정보 처리 위탁</li>
                    <li>7. 개인정보 자동 수집장치의 설치, 운영 및 거부에 관한 사항</li>
                    <li>8. 개인정보 보호를 위한 기술적, 관리적 보호 대책</li>
                    <li>9. 이용자 및 법정대리인의 권리와 그 행사방법</li>
                    <li>10. 개인정보 관리책임자 및 상담신고</li>
                    <li>11. 부칙</li>
                </ul>
                <br />
                <p>1. 개인 정보의 수집 및 이용 목적 개인정보는 생존하는 개인에 관한 정보로서 실명, 성별, 생년월일, 핸드폰번호, 주소 등의 사항으로 당사 회원 개인을 식별할 수 있는 정보를 말합니다.</p>
                <p>
                    당사가 수집한 개인정보는 다음의 목적을 위해 활용합니다.
                    <ul>
                    <li>서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 콘텐츠 제공, 물품배송 또는 청구서 등 발송, 금융거래 본인 인증 및 금융 서비스, 구매 및 요금 결제, 요금 추심</li>
                    </ul>
                </p>
                <div>
                        <p>
                            2) 회원 관리 통합 회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 가입 및 가입횟수 제한, 만14세 미만 아동 개인 정보 수집 시 법정 대리인 동의여부 확인, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달
                        </p>
                        <p>
                            3) 마케팅 및 광고에 활용 신규 서비스 개발과 이벤트 행사에 따른 정보 전달 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계
                        </p>
                        
                        <h2>2. 수집하는 개인 정보 항목 및 수집방법</h2>
                        <p>
                            사이트는 회원가입, 원활한 고객상담, 각종 서비스 신청 등 기본적인 서비스 제공을 위한 필수 정보와 고객 맞춤 서비스 제공을 위한 선택정보로 구분하여 아래와 같은 개인정보를 수집하고 있습니다.
                        </p>
                        <ul>
                            <li>필수 항목: 닉네임, 비밀번호, 전자우편, 주소</li>
                            <li>추가 항목: 휴대전화번호, 이름, 성별, 생년월일</li>
                            <li>회원맞춤정보: 임신정보, 자녀정보, 아기이름 또는 닉네임, 출산예정일(생일), 엄마분류</li>
                        </ul>
      
      <p>
        서비스 이용 과정이나 서비스 제공 업무 처리 과정에서 다음과 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
      </p>
      
      <h2>3. 수집하는 개인정보의 보유 및 이용기간</h2>
      <p>
        원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동한 보존합니다.
      </p>
      <ul>
        <li>회원탈퇴 시 보존 개인정보
          <ul>
            <li>보존 항목: 이름, 생년월일, 성별, 로그인ID, 전화번호, 주소, 휴대전화번호, 전자우편, 프로필 이미지</li>
            <li>보존 근거: 불량 이용자의 재가입 방지, 불법적 사용자에 대한 관련 기관 수사협조</li>
            <li>보존 기간: 1년</li>
          </ul>
        </li>
      </ul>
                </div>
                <div>
                <p>
                    2) 관련 법령에 의한 정보 보유 사유 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
                </p>
                <p>
                    이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
                </p>
                <ul>
                    <li>
                    - 본인 확인에 관한 기록 : 6개월 (정보통신망 이용촉진 및 정보보호 등에 관한 법률)
                    </li>
                    <li>
                    - 방문에 관한 기록 : 3개월 (통신비밀보호법)
                    </li>
                    <li>
                    - 표시/광고에 관한 기록 : 6개월 (전자상거래등에서의 소비자보호에 관한 법률)
                    </li>
                    <li>
                    - 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
                    </li>
                    <li>
                    - 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
                    </li>
                    <li>
                    - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)
                    </li>
                </ul>

                <p>
                    3) 장기 미이용회원
                </p>
                <p>
                    - 장기 미이용회원은 럽맘 최종 이용 후 1년 동안 이용 기록이 없는 회원을 말합니다.
                </p>
                <p>
                    - 이 회원의 개인정보는 별도로 분리되어 안전하게 보관하게 되며, 대상자에게 안내는 분리 보관 처리일을 기준으로 하여 최소 30일 이전에 이메일로 전송됩니다.
                </p>
                <p>
                    - 장기 미이용 회원이 미이용자 DB에 별도 분리전에 지속적으로 럽맘의 서비스를 이용하시고자 하는 경우, 럽맘 모바일앱에 로그인 하시기를 요청 드립니다.
                </p>
                <p>
                    - 이용자가 요청시에는 본인의 계정을 재사용하실 수 있습니다.
                </p>

                <p>
                    4. 개인 정보의 파기절차 및 방법
                </p>
                <p>
                    원칙적으로 개인정보 수집 및 이용목적이 달성되거나, 보유 및 이용기간이 경과된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
                </p>
                <ul>
                    <li>
                    1) 파기절차: 회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 (보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 일정 기간 저장된 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
                    </li>
                    <li>
                    2) 파기방법: 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                    </li>
                </ul>
                </div>
                <div>
                {/* 이전 내용들 */}
                
                <p>
                    5. 개인정보 제3자 활용동의
                </p>
                <p>
                    개인정보가 제공되는 제휴사와 제공정보, 제공된 정보의 이용목적은 아래와 같습니다. 개인정보보호법 제 15조, 제 17조, 제 22조 및 제 24조에 의거, 개인정보는 이용자의 동의 없이 제 3자에게 누설하거나 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
                </p>
                <ul>
                    <li>
                    1) 이용자들이 사전에 동의한 경우 - 보유 기간 및 보유 정보: 회원의 요청 또는 동의를 얻은 항목, 기간에 한하여 해당 기간동안 보유
                    </li>
                    <li>
                    2) 주문 결제가 이루어진 경우, 상담 및 배송 등의 원활한 거래이행을 위하여 관련된 정보를 필요한 범위 내에서 판매자(제3자)에게 전달합니다. 단, "럽맘"는 비즈니스 파트너와의 제휴를 통해 회원에게 제공되는 서비스의 질을 향상시키기 위해 필요한 범위 내에서 제 3자에게 제공할 수 있습니다. 개인정보 제3자활용동의에 동의를 하신 회원님들의 정보만이 제공되며, 제공된 정보는 명시된 이용목적을 벗어나 이용되지 않도록 정기적인 감시와 감독을 벌여 나갈 것입니다.
                    </li>
                </ul>
                </div>
                <table className="__se_tbl" border="0" cellPadding="0" cellSpacing="0" style={{ border: '1px solid rgb(199,199,199)' }}>
                <tbody>
                    <tr>
                    <td style={{ padding: '3px 4px 2px', color: 'rgb(102,102,102)', borderRight: '1px solid rgb(231,231,231)', textAlign: 'left', fontWeight: 'normal', width: '195px', height: '18px', backgroundColor: 'rgb(243,243,243)' }}>
                        <p style={{ textAlign: 'center' }}>&nbsp;제공받는 자</p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', color: 'rgb(102,102,102)', borderRight: '1px solid rgb(231,231,231)', textAlign: 'left', fontWeight: 'normal', width: '245px', height: '18px', backgroundColor: 'rgb(243,243,243)' }}>
                        <p style={{ textAlign: 'center' }}>이용 목적</p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', color: 'rgb(102,102,102)', borderRight: '1px solid rgb(231,231,231)', textAlign: 'left', fontWeight: 'normal', width: '291px', height: '18px', backgroundColor: 'rgb(243,243,243)' }}>
                        <p style={{ textAlign: 'center' }}>제공 항목</p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', color: 'rgb(102,102,102)', borderRight: '1px solid rgb(231,231,231)', textAlign: 'left', fontWeight: 'normal', width: '197px', height: '18px', backgroundColor: 'rgb(243,243,243)' }}>
                        <p style={{ textAlign: 'center' }}>보유 및 이용 기간</p>
                    </td>
                    </tr>
                    <tr>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '195px', height: '18px', backgroundColor: 'rgb(255,255,255)' }}>
                        <p><br /></p>
                        <p><b>&nbsp;<a href="https://luvmom2020.com/home/page/privacy/company_mall_list.php" rel="nofollow">판매자</a></b></p>
                        <p><br /></p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '245px', height: '18px', backgroundColor: 'rgb(255,255,255)' }}>
                        <p style={{ marginLeft: '0px' }}>&nbsp;상품 및 경품(서비스) 배송, 반품, 환불, 고객상담 등 정보통신서비스제공계약 및 전자상거래(통신판매)계약의 이행을 위해 필요한 업무의 처리</p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '291px', height: '18px', backgroundColor: 'rgb(255,255,255)' }}>
                        <p>&nbsp;구매자정보(이름, 전화번호, 휴대폰번호, 이메일),<br />&nbsp;수령인정보(이름, 전화번호, 휴대폰번호, 주소),<br />&nbsp;상품 구매, 취소, 반품, 교환정보, 송장정보</p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '197px', height: '18px', backgroundColor: 'rgb(255,255,255)' }}>
                        <p>서비스 제공 목적 달성 후 파기</p>
                        <p>(단, 관계법령에 정해진 규정에 따라 법정기간 동안 보관)</p>
                    </td>
                    </tr>
                    <tr>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '195px', height: '18px', backgroundColor: 'rgb(255,255,255)' }}>
                        <p><br /></p>
                        <p><a href="https://luvmom2020.com/home/page/privacy/company_list.php" rel="nofollow"><b>&nbsp;산전/산후 관련 병/의원 및 업체<br/></b></a></p>
                        <p><br /></p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '245px', height: '36px', backgroundColor: 'rgb(255,255,255)' }} colSpan="1" rowspan="2">
                        <p>개인정보를 제공받는 자의 전화를 이용한 상담서비스 소개 및 이벤트 안내, 경품지급, 사은행사 등 고객의 편의 제공</p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '291px', height: '36px', backgroundColor: 'rgb(255,255,255)' }} colSpan="1" rowspan="2">
                        <p>&nbsp;이름, 휴대폰번호, 주소, 자녀 수, 출산 예정일<br/></p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '197px', height: '36px', backgroundColor: 'rgb(255,255,255)' }} colSpan="1" rowspan="2">
                        <p>&nbsp;제공일로부터 2년 또는 개인정보 제공 동의철회 전까지</p>
                        <p>(단, 관계법령에 정해진 규정에 따라 법정기간 동안 보관)</p>
                    </td>
                    </tr>
                    <tr>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '197px', height: '36px', backgroundColor: 'rgb(255,255,255)' }} colSpan="1" rowspan="2">
                    <p style={{ color: 'rgb(102,102,102)' }}>&nbsp;(주)리더스에셋어드바이저<br/></p>
                        <p style={{ color: 'rgb(102,102,102)' }}>&nbsp;(주)케이지에이에셋<br/>베이비파스텔</p>
                    </td>
                    </tr>
                    <tr>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '195px', height: '19px', backgroundColor: 'rgb(255,255,255)' }}>
                        <p><br /></p>
                        <p>&nbsp;롯데카드, 하나카드</p>
                        <p><br /></p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '245px', height: '19px', backgroundColor: 'rgb(255,255,255)' }}>
                        <p>&nbsp;국민행복카드 관련 이벤트· 진행 · 관리(상담 · 안내 · 민원처리) 및 신청 대상자 확인</p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '291px', height: '19px', backgroundColor: 'rgb(255,255,255)' }}>
                        <p>&nbsp;이름, 휴대폰번호</p>
                    </td>
                 
                    </tr>
                    <tr>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', width: '195px', height: '17px', backgroundColor: 'rgb(255,255,255)' }}>
                        <p style={{ color: 'rgb(102,102,102)' }}>&nbsp;</p>
                        <p style={{ color: 'rgb(102,102,102)' }}>이벤트 경품 발송 업체</p>
                        <p><span style={{ color: 'rgb(102,102,102)' }}>(에스크코퍼레이션,</span><span style={{ color: 'rgb(102,102,102)' }}>(주)유투바이오)</span></p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '245px', height: '17px', backgroundColor: 'rgb(255,255,255)' }}>
                        <p>&nbsp;당첨자 확인, 경품 발송</p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '291px', height: '17px', backgroundColor: 'rgb(255,255,255)' }}>
                        <p>&nbsp;이름, 휴대폰번호, 주소</p>
                    </td>
                    <td style={{ padding: '3px 4px 2px', borderTop: '1px solid rgb(231,231,231)', borderRight: '1px solid rgb(231,231,231)', color: 'rgb(102,102,102)', width: '197px', height: '17px', backgroundColor: 'rgb(255,255,255)' }}>
                        <p>&nbsp;발송 완료 시 까지<br /></p>
                    </td>
                    </tr>
                </tbody>
                </table>

                <div style={{ textAlign: 'left' }}>
      <br />
      <br style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }} />
      <br style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }} />
      <span style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }}>
        6. 개인정보 처리 위탁 회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
        회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같으며 위탁기간은 회원탈퇴 시 혹은 위탁계약 종료 시까지 유지됩니다.
      </span>
      <br />
      <br style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }} />
      <br />
      <span style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }}>
        7. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항 회사는 계정정보를 생성하기 위해 이용자가 럽맘 프로그램을 실행 시 기기식별번호 (디바이스 아이디 또는 IMEI)를 자동으로 수집하게 됩니다. 이용자가 기기식별번호를 자동으로 수집하는 것을 거부하는 경우 '럽맘'를 이용할 수 없습니다.
      </span>
      <br />
      <br style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }} />
      <span style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }}>
        8. 개인정보 보호를 위한 기술적, 관리적 보호 대책 회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
      </span>
      <br />
      <span style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }}>
        1) 해킹 등에 대비한 대책 회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
      </span>
      <br />
      <span style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }}>
        2) 처리 직원의 최소화 및 교육 회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 럽맘 개인정보 처리방침의 준수를 항상 강조하고 있습니다.
      </span>
      <br />
      <span style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }}>
        3) 개인정보보호전담기구의 운영 그리고 사내 개인정보보호전담기구 등을 통하여 럽맘 개인정보 처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.
      </span>
      <br />
      <br />
      <span style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }}>
        9. 이용자 및 법정대리인의 권리와 그 행사방법 - 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인 정보를 조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다.
        - 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보 수정' 등)을, 가입 해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
        - 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다. - 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
      </span>
      <br />
      <br />
      <span style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }}>
        10. 개인정보 관리책임자 및 상담신고 주식회사 럽맘는 회원님의 개인 정보를 보호하기 위해 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 회원님께 알려드린 사항들에 반하는 사고가 발생할 경우 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회원님들의 신고사항에 대해 신속하고 충분한 답변을 드리겠습니다.
        개인정보 관리책임자
        * 이 름 : 김경환
        * 소속/직위 : 럽맘 기업 부설 연구소 / 팀장
        * e-mail : luvmom_official@luvmom2020.com
        * 연락처 : 1800-6931
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
        - 개인정보 침해신고센터 (한국인터넷진흥원 운영) : (국번없이) 118 / privacy.kisa.or.kr
        - 대검찰청 사이버범죄수사단 : (국번없이) 1301 / [www.spo.go.kr](http://www.spo.go.kr)
        - 경찰청 사이버범죄수사단 : 1566-0112 / [www.netan.go.kr](http://www.netan.go.kr)
      </span>
      <br />
      <br />
      <span style={{ fontFamily: 'Pretendard, sans-serif', fontSize: '13px', letterSpacing: '-0.2px', backgroundColor: 'rgb(255,255,255)' }}>
        11. 부칙 법령/정책 또는 보안기술의 변경에 따라 내용의 추가/삭제 및 수정이 있을 시에는 변경사항 시행일의 7일전부터 당사 사이트의 공지사항을 통하여 고지합니다. 제1조 이 개인정보 처리방침은 2020년 6월 28일부터 적용됩니다.
      </span>
           </div>

           <div>
      <p align="left" style={{ textAlign: 'left' }}><br /></p>
      <p align="left" style={{ textAlign: 'left' }}>
        <span style={{
          fontFamily: 'Pretendard, sans-serif',
          fontSize: '13px',
          letterSpacing: '-0.2px',
          backgroundColor: 'rgb(255, 255, 255)',
        }}>개인정보 처리방침 공고일자: 2020년 6월 21일</span>&nbsp;
      </p>
      <p align="left" style={{ textAlign: 'left' }}>
        <span style={{
          fontFamily: 'Pretendard, sans-serif',
          fontSize: '13px',
          letterSpacing: '-0.2px',
          backgroundColor: 'rgb(255, 255, 255)',
        }}>개인정보 처리방침 시행일자: 2020년 6월 28일</span>
      </p>
      <p align="left" style={{ textAlign: 'left' }}>
        <br />
      </p>
      {/* Add your privacy policy content here */}
      <p align="left" style={{ textAlign: 'left' }}>
        {/* Your privacy policy content goes here */}
      </p>
    </div>
    </div>
</div>
        </main>
      </div>
      
     <Footer city={city} setCity={setCity} />
      </>
    );
}

export default Personal;