//성민퍼블 컨텐츠 필요
import React,  { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import "../static/css/common.css";
import "../static/css/main.css";

import coupon_24_03_08 from "../static/img/main/coupon_img240308.png";
import coupon1 from "../static/img/main/coupon_1.png";
import coupon2 from "../static/img/main/coupon_2.png";

import baner1 from "../static/img/main/section_3_1.png";
import baner2 from "../static/img/main/section_3_2.png";
import baner3 from "../static/img/main/section_3_3.png";

import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";
import PopUp from "../components/Common/PopUp.js";

import UploadManual1 from '../static/img/manual/main_page.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { scrollToTop } from '../components/Common/TotheTop.js';
import {getCookie} from '../util/cookies.js';

function Main() {
    const isUserLoggedIn = localStorage.getItem('loggedIn');
    const jwtValue = getCookie('loginToken');
    const navigator = useNavigate();
    const storedCity = localStorage.getItem('selectedCity');

    const [city, setCity] = useState(storedCity);
    const [setting, setSetting] = useState(false);
    const [isPopupOpen, setPopupOpen] = useState(false);//팝업선택안해도됨
    const [selectedCity, setSelectedCity] = useState(null);

    const [mobileBanner, setMobileBanner] = useState("");
    const [pcBanner, setPcBanner] = useState("");
    const [smallBanner1, setSmallBanner1] = useState("");
    const [smallBanner2, setSmallBanner2] = useState("");
    const [smallBanner3, setSmallBanner3] = useState("");

    const [mobileBannerLink, setMobileBannerLink] = useState("");
    const [pcBannerLink, setPcBannerLink] = useState("");
    const [smallBanner1Link, setSmallBanner1Link] = useState("");
    const [smallBanner2Link, setSmallBanner2Link] = useState("");
    const [smallBanner3Link, setSmallBanner3Link] = useState("");


    const [showmobileBanner, setshowMobileBanner] = useState("");
    const [showpcBanner, setshowPcBanner] = useState("");
    const [showsmallBanner1, setshowSmallBanner1] = useState("");
    const [showsmallBanner2, setshowSmallBanner2] = useState("");
    const [showsmallBanner3, setshowSmallBanner3] = useState("");

    const [showmobileBannerLink, setshowMobileBannerLink] = useState("");
    const [showpcBannerLink, setshowPcBannerLink] = useState("");
    const [showsmallBanner1Link, setshowSmallBanner1Link] = useState("");
    const [showsmallBanner2Link, setshowSmallBanner2Link] = useState("");
    const [showsmallBanner3Link, setshowSmallBanner3Link] = useState("");
    
    const [isset, setIsset] = useState(false);

    function onSetting(){
      setSetting(true)
      if (!setting) {
        setSetting(true)
      }else{
        setSetting(false)
      }
    }
    function handleSubmit(){
      setSetting(false)
      const formData = new FormData();
      //사진보내기
      if(mobileBanner){
        formData.append('bannerImage1Url', mobileBanner);
      }
      if(pcBanner){
        formData.append('bannerImage2Url', pcBanner);
      }
      if(smallBanner1){
        formData.append('mainEventImage1Url', smallBanner1);
      }
      if(smallBanner2){
        formData.append('mainEventImage2Url', smallBanner2);
      }
      if(smallBanner3){
        formData.append('mainEventImage3Url', smallBanner3);;
      }
      
      //사진에대한링크보내기
      if(mobileBannerLink){
        formData.append('bannerImage1Link', mobileBannerLink);
      }
      if(pcBannerLink){
        formData.append('bannerImage2Link', pcBannerLink);
      }
      if(smallBanner1Link){
        formData.append('mainEventImage1Link', smallBanner1Link);
      }
      if(smallBanner2Link){
      formData.append('mainEventImage2Link', smallBanner2Link);
      }
      if(smallBanner3Link){
      formData.append('mainEventImage3Link', smallBanner3Link);
      }
      formData.append('code', city);

      if(isset){
        //수정
        fetch(`https://yuram.azurewebsites.net/api/home/${city}`, {
        method: 'PUT',
        body: formData,
        headers: {
          'Authorization': `Bearer ${jwtValue}`
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.statusCode == 401){
            alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
            navigator("/yuramAdmin")
          }else{
            window.location.reload();
          }
        })
        .catch(error => console.error('에러 발생:', error));
      }else{
        //생성
        fetch(`https://yuram.azurewebsites.net/api/home/`, {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': `Bearer ${jwtValue}`
          },
        })
          .then(response => response.json())
          .then(data => {
            if(data.statusCode == 401){
              alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
              navigator("/yuramAdmin")
            }else{
              window.location.reload();
            }
          })
          .catch(error => console.error('에러 발생:', error));
      }
      
      
    }
    // 모달관련
    const [cityModal, setCityModal] = useState(false);
    const [hideModal, setHideModal] = useState(false);
    const [modalUrl, setModalUrl] = useState(null);
    // 실제 데이터 관련
    const [popupBaner, setPopupBaner] = useState();
    let isModalHidden;

    useEffect(() => {
      isModalHidden = localStorage.getItem('hideModal');

      if(!city || city == ""){
        navigator("/")
      }
      


      fetch(`https://yuram.azurewebsites.net/api/home/${city}/`, {
                headers: {
                    'Authorization': `Bearer ${jwtValue}`
                }
      })
      .then((response) => response.json())
      .then((data) => {
        if(data){
          setIsset(data.code? true : false);
          //배너사진
          setshowMobileBanner(data.bannerImage1Url);
          setshowPcBanner(data.bannerImage2Url);
          setshowSmallBanner1(data.mainEventImage1Url);
          setshowSmallBanner2(data.mainEventImage2Url);
          setshowSmallBanner3(data.mainEventImage3Url);
          //배너링크
          setshowMobileBannerLink(data.bannerImage1Link);
          setshowPcBannerLink(data.bannerImage2Link);
          setshowSmallBanner1Link(data.mainEventImage1Link);
          setshowSmallBanner2Link(data.mainEventImage2Link);
          setshowSmallBanner3Link(data.mainEventImage3Link);
        }
      })

      fetch(`https://yuram.azurewebsites.net/api/fairlist/list`) 
      .then((response) => response.json())
      .then((data) => {
        if(data){
          const foundItem = data.find(item => item.code === city);
          if (foundItem) {
            setPopupBaner(foundItem.startBanner)

            if (isModalHidden == city) {
              setCityModal(false);
            }else{
              setCityModal(foundItem.startBanner? true : false);
            }
            setModalUrl(foundItem.startBannerURL)
          }
        }
          
      })
      .catch((error) => {
          console.error('데이터 가져오기 실패:', error);
      });
      
      if (storedCity) {
        // 저장된 도시 정보가 있으면 해당 도시로 설정
        setSelectedCity(storedCity);
        // 팝업 띄우지 않음
        setPopupOpen(false);
      } else {
        // 저장된 도시 정보가 없으면 팝업 띄움
        setPopupOpen(false);
        
      }
      
    }, []);


    useEffect(() => {
      scrollToTop();
      if (isPopupOpen) {
        // 팝업이 열렸을 때 body 스크롤을 막음
        document.body.style.overflow = 'hidden';
      } else {
        // 팝업이 닫혔을 때 body 스크롤을 원래대로 되돌림
        document.body.style.overflow = 'auto';
      }
  
      // 컴포넌트 언마운트 시 스타일 초기화
      return () => {
        document.body.style.overflow = 'auto';
      };
    }, [isPopupOpen]);

    function renderImagePc(city) {
      if (showpcBanner) {
        return (
          <>
            {showpcBannerLink && showpcBannerLink !== "x"?
              (
                <a href={showpcBannerLink}><img src={showpcBanner}/></a>
              ):(
                <img src={showpcBanner}/>
              )
            }
          </>
        )
      }
      else {
        return null;
      }
    }
    function renderImageM(city) {
      if (showmobileBanner) {
        return (
          <>
            {showmobileBannerLink && showmobileBannerLink !== "x"?
              (
                <a href={showmobileBannerLink}><img src={showmobileBanner}/></a>
              ):(
                <img src={showmobileBanner}/>
              )
            }
          </>
        );
      }
      else {
        return null;
      }
    }

    const closeCityModal = () => {
      setCityModal(false);

      if (hideModal == true) {
        localStorage.setItem('hideModal', city);
            setTimeout(() => {
              localStorage.removeItem('hideModal');
            }, 3 * 24 * 60 * 60 * 1000); // 3일 동안 유지하고 삭제
        }
    };

    function renderModal() {
      return <img src={popupBaner} />;
    }
   
    return (
      <>
      
    
      <div className='wrapper'>
        <Navi/>
        
        { setting  ?(
                  <div className='modal main_modal'>
                    <div className='modal-content'>
                    <span className='close' onClick={()=>{setSetting(false)}}>&times;</span>
                      <h2>메인페이지 사진 업로드</h2>
                      <table>
                        <tbody>
                          <tr>
                            <td><div>mobile배너</div><input type='file' onChange={(e)=>setMobileBanner(e.target.files[0])}></input></td>
                            <td><div>mobile이동링크</div><input type='text' defaultValue={showmobileBannerLink || "x"} onChange={(e)=>setMobileBannerLink(e.target.value)}></input></td>
                          </tr>
                          <tr>
                            <td><div>pc배너</div><input type='file' onChange={(e)=>setPcBanner(e.target.files[0])}></input></td>
                            <td><div>pc이동링크</div><input type='text' defaultValue={showpcBannerLink || "x"} onChange={(e)=>setPcBannerLink(e.target.value)}></input></td>
                          </tr>
                          <tr>
                            <td><div>작은배너1</div><input type='file' onChange={(e)=>setSmallBanner1(e.target.files[0])}></input></td>
                            <td><div>작은배너1이동링크</div><input type='text' defaultValue={showsmallBanner1Link || "x"} onChange={(e)=>setSmallBanner1Link(e.target.value)}></input></td>
                          </tr>
                          <tr>
                            <td><div>작은배너2</div><input type='file' onChange={(e)=>setSmallBanner2(e.target.files[0])}></input></td>
                            <td><div>작은배너2이동링크</div><input type='text' defaultValue={showsmallBanner2Link || "x"} onChange={(e)=>setSmallBanner2Link(e.target.value)}></input></td>
                          </tr>
                          <tr>
                            <td><div>작은배너3</div><input type='file' onChange={(e)=>setSmallBanner3(e.target.files[0])}></input></td>
                            <td><div>작은배너3이동링크</div><input type='text' defaultValue={showsmallBanner3Link || "x"} onChange={(e)=>setSmallBanner3Link(e.target.value)}></input></td>
                          </tr>
                        </tbody>
                      </table>
                      <button className="big_btn" type='button' onClick={()=> handleSubmit()}>저장</button>
                      <div className='manual_wrap'>
                          <div className='manual_title'>업로드 팁!</div>
                          <div>
                            배너사진 업로드와 링크를 입력할수있습니다.<br/>
                            pc는 1920px * 500px , mobile 은 500px * 350px<br/>
                            맨아래 배너 3개는 500px* 500px 로 업로드를 권장합니다.<br/><br/>
                            이동링크는 해당배너를 클릭했을때 다른 사이트를 연결할수있습니다 <br/>
                            이동링크 기본값은 소문자 x 로 아무것도 연결안하고 싶으면 x 를 작성하면 됩니다

                          </div>
                          <div className='manual_cont'>
                              <div className='manula_img_wrap'>
                                <img src={UploadManual1}></img>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                ):(
                  <></>
        )}
        <section className="section_1 pc">
        {isUserLoggedIn?(
          <div className='button_wrap2'>
            <button type='button' onClick={setting ? ()=> handleSubmit() : ()=> onSetting()}>
                { setting  ?(
                  <>
                    수정중
                  </>
                  ):(
                    <>
                    수정
                    </>
                  )}
            </button>
          </div>
        ):(
          null
        )}        
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            pagination={{ clickable: true }}
            navigation={{ nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" }}
          >
            <SwiperSlide>
              {renderImagePc(city)}
            </SwiperSlide>
          </Swiper>
        </section>
        <section className="section_1 mobile">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            pagination={{ clickable: true }}
            navigation={{ nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" }}
          >
            <SwiperSlide>
             {renderImageM(city)}
            </SwiperSlide>
          </Swiper>
        </section>
        
         <main>
            <section className="section_2">
                <div className="background_img">
                    <div className="coupon_box">
                        {/* <div className="coupon_1">
                            <Link to="/member">
                              <img src={coupon1}/>
                            </Link>
                        </div>
                        <div className="coupon_2">
                        <Link to="/brandApply">
                              <img src={coupon2}/>
                            </Link>
                        </div> */}
                         <Link to="/member">
                              <img src={coupon_24_03_08}/>
                            </Link>
                    </div>
                </div>
            </section>
            {/* <!-- ----section_3--- --> */}
            <div className="section_3_back">
                <div className="section_3_img_box">
                    <div className="section_3_img">
                      <>
                        {showsmallBanner1Link && showsmallBanner1Link !== "x"?
                          (
                            <a href={showsmallBanner1Link}><img src={showsmallBanner1 || baner1}/></a>
                          ):(
                            <img src={showsmallBanner1 || baner1}/>
                          )
                        }
                      </>
                    </div>
                    <div className="section_3_img">
                    <>
                        {showsmallBanner2Link && showsmallBanner2Link !== "x"?
                          (
                            <a href={showsmallBanner2Link}><img src={showsmallBanner2 || baner2}/></a>
                          ):(
                            <img src={showsmallBanner2 || baner2}/>
                          )
                        }
                      </>
                    </div>
                    <div className="section_3_img">
                      <>
                        {showsmallBanner3Link && showsmallBanner3Link !== "x"?
                          (
                            <a href={showsmallBanner3Link}><img src={showsmallBanner3 || baner3}/></a>
                          ):(
                            <img src={showsmallBanner3 || baner3}/>
                          )
                        }
                      </>
                    </div>
                </div>
            </div>
        </main>
      </div>
      <Footer city={city} setCity={setCity} />


      {isPopupOpen && 
      
          <PopUp 
            isPopupOpen={isPopupOpen} 
            setPopupOpen={setPopupOpen}
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
            setCity={setCity}
          />}

          {cityModal && isModalHidden !== city ? (
                <div className='cityModal'>
                  <div className='cityModalCont'>
                      <div className='modal_img'>
                        {!modalUrl ? (
                          <a href={modalUrl}>
                          {renderModal()}
                          </a>
                        ):(
                          <>
                          {renderModal()}
                          </>
                        )}
                      
                      </div>
                      <div className='close_wrap'>
                        <div>
                        <input type="checkbox" checked={hideModal} onChange={(e) => { setHideModal(!hideModal) }} />
                          3일 동안 안보기
                          <button onClick={closeCityModal} className='closeButton'>닫기</button>
                        </div>
                      </div>
                  </div>
              </div>
          )
          :
          null
          }
      </>
    );
}

export default Main;