//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ReactQuill from "react-quill";
import Editor from "./editor.js";

import "../static/css/common.css";
import "../static/css/Introduce.css";

import MiniNavi from "../components/Common/MiniNavi.js"
import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";

import box_1 from "../static/img/main/brandEventDetail/L1_s.jpg";
import { scrollToTop } from '../components/Common/TotheTop.js';
import {getCookie} from '../util/cookies.js';

function BrandListDetail() {
  const navigator = useNavigate();
  const jwtValue = getCookie('loginToken');
  
  const isUserLoggedIn = localStorage.getItem('loggedIn');
  const [setting, setSetting] = useState(false);
  const [city, setCity] = useState(localStorage.getItem('selectedCity') || '');
  const [itemData, setItemData] = useState([]);
  const [updateAt, setUpdateAt] = useState();

  const [showtitle, setShowtitle] = useState("");
  const [showthumbnailUrl, setshowthumbnailUrl] = useState("");
  const [showauthor, setShowAuthor] = useState("메쎄유람");
  const [showcontact, setShowContact] = useState("010-9241-7188");
  const [showemail, setShowEmail] = useState("m_yuram@naver.com");
  const [showcontent, setshowcontent] = useState("m_yuram@naver.com");

  const [title, setTitle] = useState();
  const [content, setcontent] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const [author, setAuthor] = useState();
  const [contact, setContact] = useState();
  const [email, setEmail] = useState();
  const [createdAt, setCreatedAt] = useState();
  
  const handleEditorChange = (html) => {
    // 여기서 전달된 값(html)을 사용할 수 있습니다.
    console.log(html);
    setcontent(html);
  };

  const { id } = useParams();
  const navi_name = "베세페 이벤트";
  const navi_name_detail = "참가브랜드 이벤트";

  useEffect(() => {
    scrollToTop();

    //수정일 보내기
    function toLocalISOString(date) {
      const off = date.getTimezoneOffset();
      const absoff = Math.abs(off);
      return new Date(date.getTime() - off * 60 * 1000).toISOString().slice(0, -1) + (off > 0 ? '-' : '+') + (absoff / 60).toString().padStart(2, '0') + ':' + (absoff % 60).toString().padStart(2, '0');
    }
    const date = new Date();
    const formatedate = toLocalISOString(date);
    setUpdateAt(formatedate)
    //const selectedItem = parseInt(id, 10);
    fetch(`https://yuram.azurewebsites.net/api/brandlist/${city}/${id}`, {
      method: 'GET',
    })
    .then(response => response.json())
    .then(data => {
      setItemData(data)
      
      setShowtitle(data.title)
      setshowthumbnailUrl(data.thumbnailUrl)
      setShowAuthor(data.author)
      setShowContact(data.contact)
      setShowEmail(data.email)
      setshowcontent(data.content)
      
    })
    .catch(error => console.error('에러 발생:', error));
  },[]);

  function onSetting(){
    setSetting(true)
    if (!setting) {
      setSetting(true)
    }else{
      setSetting(false)
    }
  }

  function handleSubmit(){
    const config = {};
    if (!content){
      alert("내용을 입력해주세요")
      return false;
    }
    if (title || showtitle) config.title = title || showtitle;
    if (content || showcontent)config.content = content || showcontent;
    if (city) config.code = city;
    if (author) config.author = author;
    if (contact) config.contact = contact;
    if (email) config.email = email;

     //수정
     fetch(`https://yuram.azurewebsites.net/api/brandlist/${city}/${id}`, {
      headers: {
        "Content-Type" : "application/json",
        'Authorization': `Bearer ${jwtValue}`
      },
      method: 'PUT',
      body: JSON.stringify(config)
    })
      .then(response => response.json())
      .then(data => {
        if(data){

          if(data.statusCode == 401){
            alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
            navigator("/yuramAdmin")
          }else{
            setSetting(false)
            alert("수정되었습니다!");
            window.location.reload();
          }
       
        }
        
      })
      .catch(error => console.error('에러 발생:', error));
  }

  function handleDelete(){
     //삭제
     const result = window.confirm('삭제하시겠습니까?');
    if (result) {
      fetch(`https://yuram.azurewebsites.net/api/brandlist/${city}/${id}`, {
      method: 'Delete',
      headers: {
        'Authorization': `Bearer ${jwtValue}`
      },
    })
      .then(response => response.json())
      .then(data => {
        if(data.statusCode == 401){
          alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
          navigator("/yuramAdmin")
        }else{
          alert("삭제되었습니다")
          navigator('/brandlist');
        }
      })
      .catch(error => console.error('에러 발생:', error));
    }
  }

  const formattedDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
  
    const date = new Date(dateString);
    return date.toLocaleString('ko-KR', options);
  };

    return (
      <>
      <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
          <MiniNavi navi_name={navi_name}/>
          <div className='detail_cont'>
          <div className='detail_tit'>{navi_name_detail}</div>
            <div className='back_btn'><Link to="/brandlist">뒤로가기</Link></div>
            {/* 선택된 항목의 정보를 출력합니다. */}
            <div className='detail_table_wrap2'>
              <div className='hit'>조회수 : {itemData.hit}</div>
              {isUserLoggedIn?(
                <div className='button_wrap'>
                  <button type='button' onClick={ setting ? ()=>handleSubmit() : () => onSetting()}>
                      { setting  ?(
                        <>
                          저장
                        </>
                        ):(
                          <>
                          수정
                          </>
                        )}
                  </button>
                  <button type='button' onClick={handleDelete} style={{marginLeft:"10px", background:"red"}}>삭제</button>
                </div>
              ):(
                null
              )}
              <table>
                <tbody>
                  <tr>
                    <td>제목</td>
                    <td>{itemData && itemData.title}</td>
                  </tr>
                  <tr>
                    <td>작성일</td>
                    <td>{formattedDate(itemData.createdAt)}</td>
                  </tr>
                  <tr>
                    <td>작성자</td>
                    <td>{itemData.author}</td>
                  </tr>
                  {/* <tr>
                    <td>전화번호</td>
                    <td>{itemData.contact}</td>
                  </tr>
                  <tr>
                    <td>이메일</td>
                    <td>{itemData.email}</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <div className='contents_div'>
            <img src={itemData.thumbnailUrl}/>
                {itemData.content ?
                  <>
                  <div dangerouslySetInnerHTML={{ __html: itemData.content }} />
                  </>
                  : 
                  <>
                  </>
                }
            </div>
            { setting  ?(
                  <div className='write_modal_box'>
                    <label className='write_title'>글쓰기</label>
                    <table>
                      <tbody>
                        <tr>
                          <td>제목</td>
                          <td><input type='text' defaultValue={showtitle} onChange={(e)=> setTitle(e.target.value)}></input></td>
                        </tr>
                        <tr>
                          <td>작성자</td>
                          <td><input type='text' defaultValue={ showauthor || "메쎄유람"} onChange={(e)=> setAuthor(e.target.value)}></input></td>
                        </tr>
                        {/* <tr>
                          <td>연락처</td>
                          <td><input type='text' defaultValue={ showcontact || "010-9241-7188"} onChange={(e)=> setContact(e.target.value)}></input></td>
                        </tr>
                        <tr>
                          <td>이메일</td>
                          <td><input type='text' defaultValue={ showemail || "m_yuram@naver.com"} onChange={(e)=> setEmail(e.target.value)}></input></td>
                        </tr> */}
                        <tr>
                          <td>내용</td>
                          <td>
                                {/* <ReactQuill 
                                  defaultValue={showcontent}
                                  onChange={(content, delta, source, editor) => setcontent(editor.getHTML())}
                                /> */}
                                <Editor onEditorChange={handleEditorChange}/>
                            
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
                    <button className='close_btn' onClick={(e)=>{setSetting(false)}}>닫기</button>
                    <div className='button_wrap'>
                    <button type='button' onClick={handleSubmit}>
                        저장
                    </button>
                    </div>
                  </div>
                ):(
                 null
                )}   
            
          </div>
        </main>
      </div>
     <Footer city={city} setCity={setCity} />
      </>
    );
}

export default BrandListDetail;