//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import "../static/css/common.css";
import "../static/css/Introduce.css";
import poster from "../static/img/VisitTip/N230822114608va38jzqic9.png"

import MiniNavi from "../components/Common/MiniNavi"
import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";
import { scrollToTop } from '../components/Common/TotheTop.js';

import {getCookie} from '../util/cookies.js';

function VisitTip() {
    const isUserLoggedIn = localStorage.getItem('loggedIn');
    const jwtValue = getCookie('loginToken');
    const [setting, setSetting] = useState(false);

    const [city, setCity] = useState(localStorage.getItem('selectedCity') || '');
    const navi_name = "관람안내";
    const navi_name_detail = "방문 TIP"

    const [codes, setCodes] = useState([]);
    const [image,setImage] = useState("");
    const [imageUrl,setImageUrl] = useState("");
    
    let foundEvent;
    
    function onSetting(){
      setSetting(true)
      if (!setting) {
        setSetting(true)
      }else{
        setSetting(false)
      }
    }
    
    function handleSubmit(){
      setSetting(false)
      const formData = new FormData();

      formData.append('code', city);
      formData.append('imageURL', image);

      if(imageUrl !== ""){
        fetch(`https://yuram.azurewebsites.net/api/tip/${city}`, {
          method: 'PUT',
          body: formData,
          headers: {
            'Authorization': `Bearer ${jwtValue}`
          }
        })
          .then(response => response.json())
          .then(data =>{
            if(data.statusCode == 401){
              alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
              navigator("/yuramAdmin")
            }else{
              alert("수정되었습니다")
              window.location.reload();
            }
          })
          .catch(error => console.error('에러 발생:', error));
      }else{
        fetch('https://yuram.azurewebsites.net/api/tip', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': `Bearer ${jwtValue}`
          }
        })
          .then(response => response.json())
          .then(data =>{
            if(data.statusCode == 401){
              alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
              navigator("/yuramAdmin")
            }else{
              window.location.reload();
            }
          })
          .catch(error => console.error('에러 발생:', error));
      }
      }
      

    useEffect(() => {
      scrollToTop();
      // 박람회 별 방문팁 이미지 찾기
      fetch(`https://yuram.azurewebsites.net/api/tip/${city}`, {
          method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${jwtValue}`
            }
          })
        .then(response => response.json())
        .then(data => {
          if(data){
            if(data.imageUrl){
              setImageUrl(data.imageUrl);
            }
          }
        })
        .catch(error => console.error('Error:', error));

    },[]);


    return (
      <>
      <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
            <MiniNavi navi_name={navi_name}/>
            <div className='detail_cont'>
            <div className='detail_tit'>{navi_name_detail}</div>
            {isUserLoggedIn?(
             <div className='button_wrap'>
              <button type='button' onClick={setting ? ()=> handleSubmit() : ()=> onSetting()}>
                  { setting  ?(
                    <>
                      저장
                    </>
                    ):(
                      <>
                      수정
                      </>
                    )}
              </button>
              </div>
          ):(
            null
          )}

                { setting  ?(
                  <>
                    <input type='file' onChange={(e)=>setImage(e.target.files[0])}></input>
                  </>
                ):(
                  <>

                  </>
                )}

                  {imageUrl?(
                  <img src={imageUrl}/>
                  ):(
                  <img src={poster}/>
                  )}
                
           </div>
        </main>
      </div>
     <Footer city={city} setCity={setCity} />
      </>
    );
}

export default VisitTip;