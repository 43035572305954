import React from 'react';
import { Link } from 'react-router-dom';
import arrow from "../static/img/arrow.svg";

import "../static/css/common.css";
import "../static/css/selectPlace.css";

const ListItem = ({ index, handleLinkClick, code, name, startDate, endDate, color }) => {
  const handleClick = () => {
    handleLinkClick(code);
  };

  return (
    <li onClick={handleClick}>
      <Link to="/main">
        <div className={`Link_click color_${index}`}>
          <div className={`Link_title`}>
            {name.length === 2 ? name[0] + " " + name[1] : name}
          </div>
          <div className={`Link_date ${index === 3 ? 'no-margin' : ''}`}>
            {startDate} - {endDate}
          </div>
          {/* <div className={`Link_icon`}><img src={arrow} alt="Arrow"/></div> */}
        </div>
      </Link>
    </li>
  );
};

export default ListItem;
