//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactQuill from "react-quill";
import Editor from "./editor";
import "../static/css/common.css";
import  "../static/css/notice.css";


import MiniNavi from "../components/Common/MiniNavi.js"
import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";
import box_1 from "../static/img/main/brandEventDetail/L1.jpg";
import { scrollToTop } from '../components/Common/TotheTop.js';
import {getCookie} from '../util/cookies.js';

function Notice() {
    const isUserLoggedIn = localStorage.getItem('loggedIn');
    const jwtValue = getCookie('loginToken');
    const [setting, setSetting] = useState(false);
    const [city, setCity] = useState(localStorage.getItem('selectedCity') || '');
    const navi_name = "공지사항";
    const navi_name_detail = "공지사항";

    //초기값
    const [title, setTitle] = useState("");
    const [content, setcontent] = useState("");
    const [thumbnailUrl, setEmailthumbnailUrl] = useState("");
    const [author, setAuthor] = useState("메쎄유람");
    const [contact, setContact] = useState("010-9241-7188");
    const [email, setEmail] = useState("m_yuram@naver.com");
    const [createdAt, setCreatedAt] = useState();

    const [showauthor, setShowAuthor] = useState("메쎄유람");
    const [showcontact, setShowContact] = useState("010-9241-7188");
    const [showemail, setShowEmail] = useState("m_yuram@naver.com");
    const [itemData, setItemData] = useState([]);

    const handleEditorChange = (html) => {
      // 여기서 전달된 값(html)을 사용할 수 있습니다.
      console.log(html);
      setcontent(html);
    };


    function onSetting(){
      setSetting(true)
      if (!setting) {
        setSetting(true)
      }else{
        setSetting(false)
      }
    }

    function handleSubmit(){

      if(!title){
        alert("제목을 입력해주세요")
        return false;
      }
      const config = {
        "title": title,
        "content": content,
        "code": city,
        "author": author,
        // "contact": contact,
        // "email": email,
        "createdAt": createdAt,
      }
      console.log(config)
      // if(createdAt){
      //   console.log(createdAt)
      //   formData.append('createdAt', createdAt);
      // }
      //생성
      fetch(`https://yuram.azurewebsites.net/api/notice/`, {
        headers: {
          "Content-Type" : "application/json",
          'Authorization': `Bearer ${jwtValue}`
        },
        method: 'POST',
        body: JSON.stringify(config)
      })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          if(data.statusCode == 401){
            alert("만료되었습니다. 다시 로그인 해주세요");
            navigator("/yuramAdmin")
          }else{
            setSetting(false)
            window.location.reload(); 
          }
          
        })
        .catch(error => {

          console.error('에러 발생:', error)
          console.log(config)
        });


      
    }

    useEffect(() => {
      scrollToTop();
        //생성일 보내기
        function toLocalISOString(date) {
          const off = date.getTimezoneOffset();
          const absoff = Math.abs(off);
          return new Date(date.getTime() - off * 60 * 1000).toISOString().slice(0, -1) + (off > 0 ? '-' : '+') + (absoff / 60).toString().padStart(2, '0') + ':' + (absoff % 60).toString().padStart(2, '0');
        }
        const date = new Date();
        const formatedate = toLocalISOString(date);
        setCreatedAt(formatedate)
      fetch(`https://yuram.azurewebsites.net/api/notice/${city}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${jwtValue}`
        },
      })
        .then(response => response.json())
        .then(data => {
          setItemData(data)
        })
        .catch(error => console.error('에러 발생:', error));
    },[]);
    
    let payBackList = [];
    if(city){
      payBackList = [
        {
          title: "[루미] 박람회 특가 이벤트",
          img: box_1,
          to : "/noticeDetail/0"
      },
      ]
    }else{
      payBackList = []

    }
    

    const mappedPayBackList = itemData.length > 0 && itemData.map((item, index) => {
      return (
        <li key={item.id}>
          <Link to={"/noticeDetail/" + item.id}>
          {/* <div className='event_img_wrap'>
            <img src={item.thumbnailUrl || box_1}/>
          </div> */}
          <div className='mappedPayTitle'>
            <div>{index + 1}</div>
            <div>{item.title}</div>
            <div>{item.createdAt && item.createdAt.split('T')[0]}</div>
            <div>{item.author}</div>
          </div>
          </Link>
        </li>
      )
    });
   
    return (
      <>
      <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
           <MiniNavi navi_name={navi_name}/>
            <div className='detail_cont'>
                <div className='detail_tit'>{navi_name_detail}</div>
                {isUserLoggedIn?(
                  <div className='write_button'>
                    <button type='button' onClick={onSetting}>
                      글쓰기
                    </button>
                  </div>
                ):(
                  null
                )}

                {mappedPayBackList && mappedPayBackList.length ? (
                  <div className='notice_wapper'>
                  <ul>
                    <li>
                      <div className='mappedPayTitle_header'>
                        <div>no.</div>
                        <div>제목</div>
                        <div>작성일</div>
                        <div>작성자</div>
                      </div>
                    </li>
                    {mappedPayBackList}
                  </ul>
                  </div>
                ):(
                  <div className='text_center'>작성된 게시글이 없습니다</div>
                )}
               
                
                { setting  ? (
                  <div className='write_modal_box'>
                    <label className='write_title'>글쓰기</label>
                    <table>
                      <tbody>
                        <tr>
                          <td>제목</td>
                          <td><input type='text' onChange={(e)=> setTitle(e.target.value)}></input></td>
                        </tr>
                        <tr>
                          <td>작성자</td>
                          <td><input type='text' defaultValue={"메쎄유람"} onChange={(e)=> setAuthor(e.target.value)}></input></td>
                        </tr>
                        <tr>
                          <td>내용</td>
                          <td>
                            <Editor onEditorChange={handleEditorChange}/>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button class="close_btn" onClick={(e)=>{setSetting(false)}}>닫기</button>

                    <div className='button_wrap'>
                    <button type='button' onClick={handleSubmit}>
                        저장
                    </button>
                    </div>
                  </div>
                ):(
                 null
                )}
            </div>
        </main>
      </div>
     <Footer city={city} setCity={setCity} />
      </>
    );
}

export default Notice;