//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import "../static/css/common.css";
import "../static/css/Introduce.css";


import MiniNavi from "../components/Common/MiniNavi"
import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";
import { scrollToTop } from '../components/Common/TotheTop.js';

import seoul_notice from "../static/img/main/notice/notice.png"
function BrandApply() {
 const [city, setCity] = useState(localStorage.getItem('selectedCity') || '');
    const navi_name = "공지사항";
    const navi_name_detail = "업체 참가 문의";
    useEffect(() => {
      scrollToTop();
    },[]);
    return (
      <>
      <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
           <MiniNavi navi_name={navi_name}/>
           {/* {city != "메세유람_사전등록_광주" ? (
            <div className='detail_cont'>
                <div className='detail_tit'>{navi_name_detail}</div>
                <div style={{"textAlign" : "center"}}>준비중입니다</div>
           </div>
           ):(
            <div className='detail_cont'>
                <div className='detail_tit'>{navi_name_detail}</div>
                <img src={seoul_notice}/>
           </div>
           )}  */}
           <div className='detail_cont'>
                <div className='detail_tit'>{navi_name_detail}</div>
                <img src={seoul_notice}/>
           </div>
           
        </main>
      </div>
     <Footer city={city} setCity={setCity} />
      </>
    );
}

export default BrandApply
;