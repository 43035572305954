import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
function MiniNavi({ navi_name }) {
  let title = "";
  let subNavi = [];
  // 조건에 따라 값을 설정
  if (navi_name === '소개') {
    title = "소개";
    subNavi = [
      {
        title: "소개",
        src: '/introduce'
      }
    ]
  } else if (navi_name === '관람안내') {
    title = "관람안내";
    subNavi = [
      {
        title: "방문 TIP",
        src: '/visitTip'
      },
      {
        title: "참가브랜드리스트",
        src: '/brandList'
      },
      {
        title: "오시는길",
        src: '/visit'
      }
    ]
  } else if (navi_name === '베세페 이벤트') {
    title = "베세페 이벤트";
    subNavi = [
      {
        title: "유람 이벤트/페이백안내",
        src: '/payBack'
      },
      {
        title: "참가브랜드 이벤트",
        src: '/brandEvent'
      }
    ]
  } else if (navi_name === '공지사항') {
    title = "공지사항";
    subNavi = [
      {
        title: "공지사항",
        src: '/notice'
      },
      {
        title: "업체참가문의",
        src: '/brandApply'
      }
    ]
  } else if (navi_name === '사전등록') {
    title = "사전등록";
    subNavi = [
      // {
      //   title: "사전등록",
      //   src: '/member'
      // }
    ]
  }else if(navi_name ==='약관'){
    title = "개인정보처리방침";
    subNavi = [
      {
        title: "개인정보처리방침",
        src: '/personal'
      },
      {
        title: "이용약관",
        src: '/usedRules'
      }
    ]
  }

  return (
    <div className="MiniNavi">
      <div className='MiniTitle'>{title}</div>
      <ul className='MiniNavi_sub orbit-regular'>
      {subNavi.map((item, index) => (
        <li key={index}>
          <Link to={item.src}>{item.title}</Link>
        </li>
      ))}

      </ul>
    </div>
  );
}

export default MiniNavi;
