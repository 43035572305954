//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import "../static/css/common.css";
import MiniNavi from "../components/Common/MiniNavi"
import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";
import { scrollToTop } from '../components/Common/TotheTop.js';

function UsedRules() {
 const [city, setCity] = useState(localStorage.getItem('selectedCity') || '');
    const navi_name = "약관";
    const navi_name_detail = "이용약관";
    useEffect(() => {
      scrollToTop();
    },[]);
    return (
      <>
      <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
          <MiniNavi navi_name={navi_name}/>
           
          <div className='detail_cont'>
              <div className='detail_tit'>{navi_name_detail}</div>
            
              <div className='usedRules'>
      <h2>제1조(목적)</h2>
      <p>
        이 약관은 주식회사 ㈜럽맘(이하 '회사')가 제공하는 럽맘 서비스(이하 '서비스')와 관련하여,
        '회사'와 이용고객(또는 '회원') 간에 서비스의 이용조건 및 절차, '회사'와 '회원' 간의 권리, 의무 및 기타 필요한 사항을 규정함을 목적으로 합니다.
        본 약관은 PC통신, 스마트폰(안드로이드폰, 아이폰 등)앱 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 준용 됩니다.
      </p>

      <h2>제2조(정의)</h2>
      <p>
        ① '서비스'라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 '회원'이 이용할 수 있는 럽맘 서비스를 의미 합니다.
      </p>
      <p>
        ② '회원'이라 함은 서비스에 개인정보를 제공하여 회원등록을 한 자로서, 사이트에 전자우편주소를 등록하고 사이트의 정보를 지속적으로 제공받으며, 사이트가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
      </p>
      <p>
        ③ '비회원'이라 함은 '회원'으로 가입하지 않고 '회사'가 제공하는 서비스를 이용하는 자를 말합니다.
      </p>
      <p>
        ④ '비밀번호(Password)'라 함은 '회원'의 동일성 확인과 '회원'의 권익 및 비밀보호를 위하여 '회원' 스스로가 설정하여 사이트에 등록한 영문과 숫자의 조합을 말합니다.
      </p>
      <p>
        ⑤ 아이디(ID) 라 함은 '회원'의 식별과 서비스 이용을 위하여 '회원'이 정하고 '회사'가 승인하는 문자와 숫자의 조합을 의미합니다.
      </p>
      <p>
        ⑥ '게시물'이라 함은 '회원'이 서비스를 이용함에 있어 서비스 상에 게시한 부호, 문자, 음성, 음향, 화상, 동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
      </p>
      <p>
        ⑦ 위치정보 라 함은 위치정보의보호 및 이용등에 관한 법률 제2조 제1호 '위치정보' 및 제2호 '개인위치정보'를 말합니다.
      </p>
      <p>
        ⑧ '주문거래'이라 함은 '회원'이 서비스를 이용함에 있어 결제서비스를 이용하여 매매거래가 이루어지는 행위 일체를 말합니다.
      </p>
      <p>
        ⑨ '포인트'라 함은 '회원'이 서비스 이용을 함으로써 '회사'가 '회원'에게 제공하는 사이버 화폐를 말합니다.
      </p>
      <h2>제3조 (약관의 명시와 설명 및 개정)</h2>
      <p>
        ① '회사'는 이 약관의 내용을 '회원'이 쉽게 알 수 있도록 앱 화면에 게시합니다.
      </p>
      <p>
        ② '회사'는 '약관의 규제 등에 관한 법률', '전자거래 기본법', '전자서명법', '정보통신망 이용촉진 등에 관한 법률',
        '소지자기본법', '전자상거래 등에서의 소비자보호에 관한 법률' 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
      </p>
      <p>
        ③ '회사'가 약관을 개정할 경우에는 기존약관과 개정약관 및 개정약관의 적용일자와 개정사유를 명시하여 현행약관과 함께 그 적용일자를 30일 전부터 일정기간 공지하고, SMS, 로그인시 동의창 등의 전자적 수단을 통해 고지합니다.
      </p>
      <p>
        ④ '회사'가 전항에 따라 개정약관을 공지 또는 통지하면서 '회원'에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지 하였음에도 '회원'이 명시적으로 거부의 의사표시를 하지 아니한 경우 '회원'이 개정약관에 동의한 것으로 봅니다.
      </p>
      <p>
        ⑤ '회원'이 개정약관의 적용에 동의하지 않는 경우 '회사'는 개정 약관의 내용을 적용할 수 없으며, 이 경우 '회원'은 이용계약을 해지 할 수 있습니다. 다만, 기존약관을 적용할 수 없는 특별한 사정이 있는 경우에는 '회사'는 이용계약을 해지 할 수 있습니다.
      </p>
      <p>
        '회원'이 약관개정 동의를 하지 않을 경우 '회사'는 해당 '회원'에게 개정된 약관에 따른 서비스를 제한할 수 있습니다.
      </p>

      <h2>제4조(약관의 해석)</h2>
      <p>
        ① '회사'는 유료서비스 및 개별 서비스에 대해서는 별도의 이용약관 및 정책(이하 유료서비스 약관 등)을 둘 수 있으며, 해당 내용이 약관과 상충할 경우에는 소비자에게 불이익이 제공되지 않는 한에서 유료서비스 약관 등 이 우선하여 적용됩니다.
      </p>
      <p>
        ② 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 유료서비스약관 등 및 관계법령 또는 상관례에 따릅니다.
      </p>

      <h2>제5조(이용계약 체결)</h2>
      <p>
        ① 서비스 이용계약은 '회원'이 되고자 하는 분(이하 '가입신청자')이 약관에 동의하고 가입을 신청함으로써 체결됩니다.
      </p>
      <p>
        ② 가입신청자의 신청에 대하여 '서비스' 이용을 승낙함을 원칙으로 합니다. 다만, '회사'는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
      </p>
      <p>
        1) 가입 신청자는 이 약관에 의하여 이전에 '회원'자격을 상실한 적이 있는 경우, 단 '회사'의 '회원' 재가입 승낙을 얻은 경우에는 예외로 한다.
      </p>
      <p>
        2) 타인의 명의를 이용한 경우
      </p>
      <p>
        3) 허위의 정보를 기재 하거나 '회사'가 제시하는 내용을 기재하지 않은 경우
      </p>
      <p>
        4) 14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우
      </p>
      <p>
        5) 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하여 신청하는 경우
      </p>
      <p>
        6) 제1항에 따른 신청에 있어 '회사'는 '회원'의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
      </p>
      <p>
        7) '회사'는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
      </p>
      <p>
        8) 제2항과 제4항에 따라 '회원'가입신청의 승낙을 하지 않거나 유보한 경우 '회사'는 원칙적으로 이를 가입신청자에게 알리도록 합니다.
      </p>
      <p>
        9) '회사'는 '회원'에 대해 '회사'정책에 따라 등급 별로 구분하여 이용시간, 이용회수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
      </p>
      <h2>제6조('회원' 정보의 변경)</h2>
      <p>
        ① '회원'은 서비스 내 '프로필' 화면을 통하여 본인의 개인정보를 열람하고 수정할 수 있습니다. 단, 서비스의 관리를 위하여 ID(이메일주소), 단말기 식별번호(디바이스 아이디 또는 IMEI) 비롯한 일부 정보는 수정할 수 없습니다.
      </p>
      <p>
        ② '회원'은 가입신청 시 기재한 사항이 변경되었을 경우 이를 사이트에서 수정하거나 '회사'에 통보해야 하며 미수정 또는 통보하지 않아 발생한 불이익 발생에 대하여 '회사'는 일체의 책임이 없습니다.
      </p>

      <h2>제7조('회사'의 별도 서비스 제공안내)</h2>
      <p>
        ① '회사'가 운영하는 별도 관련 서비스에 대한 안내를 e-mail 혹은 Push, SMS 등의 서비스로 제공할 수 있습니다.
      </p>
      <p>
        ② '회사'는 서비스 개선 및 '회원' 대상의 서비스 소개 등의 목적으로 '회원'의 동의 하에 추가적인 개인정보를 요구할 수 있습니다.
      </p>

      <h2>제8조(권리의 귀속 및 저작물의 이용)</h2>
      <p>
        ① '회사'가 '회원'에게 제공하는 각종 서비스에 대한 저작권을 포함한 일체의 권리는 '회사'에 귀속되며 '회원'이 서비스를 이용하는 과정에서 작성한 게시물에 대한 저작권을 포함한 일체에 관한 권리는 별도의 의사표시가 없는 한 해당 '회원'에게 귀속됩니다.
      </p>
      <p>
        ② '회원'은 게시물을 작성시 관련 법령(저작권법, 전기통신기본법 등)을 준수하여야 하며, 이를 준수하지 않아 발생하는 모든 책임은 해당 게시물을 작성한 '회원'에게 있습니다.
      </p>

      <h2>제9조(개인정보의 보호 및 제공)</h2>
      <p>
        ① '회사'는 관계법령이 정하는 바에 따라 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위해 노력합니다. 이용자 개인정보의 보호 및 사용에 대해서는 관련 법령 및 '회사'의 개인정보 보호정책이 적용됩니다. 단 '회사'의 앱에서 링크된 사이트에서는 '회사'의 개인정보 보호정책이 적용되지 않습니다. 또한 '회사'는 이용자의 귀책사유로 인해 노출된 정보에 대해서 일체의 책임을 지지 않습니다.
      </p>
      <p>
        ② '회사'는 EM, TM, DM, 방문 등 마케팅의 목적으로 '회원'의 개인정보 및 기타 정보를 활용할 수 있습니다.
      </p>
      <p>
        ③ '회사'는 '회원'의 동의 하에 '회원'이 제공한 개인정보 및 기타 정보를 제 3자에게 제공할 수 있습니다.
      </p>
      <h2>제10조 (서비스의 변경)</h2>
      <p>
        ① '회사'는 이용 감소로 인한 원활한 서비스 제공의 곤란 및 수익성 악화, 기술 진보에 따른 차세대 서비스로의 전환 필요성, 서비스 제공과 관련한 '회사' 정책의 변경 등 기타 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 '서비스'를 변경 또는 중단할 수 있습니다.
      </p>
      <p>
        ② '회사'는 무료로 제공되는 서비스의 일부 또는 전부를 '회사'의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 '회원'에게 별도의 보상을 하지 않습니다.
      </p>
      <p>
        ③ 서비스를 변경 또는 서비스 중단이 있는 경우에는 변경 또는 중단될 서비스의 내용 및 사유와 일자 등은 그 변경 또는 중단 전에 '회사' 웹사이트 및 서비스를 통하여 30일의 전에 사전 공지합니다. 단, 변경사유 또는 내용을 구체적으로 공지하기 어려운 경우에는 그 이유를 밝힙니다.
      </p>

      <h2>제11조(서비스의 일시 중단)</h2>
      <p>
        '회사'는 서비스관련 설비 보수점검, 교체 및 고장, 통신두절 등 기술상 업무상의 이유로 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 사전에 통지함을 원칙으로 하지만, 부득이한 사유가 발생할 경우 사후에 통지할 수 있으며 이에 대한 보상이 필요한 경우 통상적으로 예상 가능한 범위 내에서 보상을 원칙으로 하며, 불가항력에 의한 경우에는 보상해 드리지 않습니다.
      </p>
      <p>
        서비스의 일시 중단은 이메일 또는 서비스 내 공지사항, PUSH, SMS, 서비스 웹사이트에 게시하는 방법 등으로 통지합니다.
      </p>

      <h2>제12조('회원'에 대한 통지)</h2>
      <p>
        ① '회사'가 '회원'에게 통지를 해야 할 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편, 전자쪽지 등으로 통지를 할 수 있습니다.
      </p>
      <p>
        ② '회사'는 '회원' 전체에게 통지를 해야 할 경우 7일 이상 '회사'의 '앱' 또는 '공지사항'에 게시함으로써 전항을 갈음 할 수 있습니다.
      </p>
      <h2>제13조('회원'의 의무)</h2>
      <p>
        ① '회원'은 다음 행위를 하여서는 안 됩니다.
      </p>
      <ol>
        <li>'회원'정보에 허위 내용을 등록하는 행위</li>
        <li>'회사'의 서비스에 게시된 정보를 변경하거나 서비스를 이용하여 얻은 정보를 '회사'의 사전 승낙 없이 영리 또는 비영리 목적으로 복제, 출판, 방송 등에 사용하거나 제 3자에게 제공하는 행위</li>
        <li>'회사' 기타 제 3자의 명예를 훼손하거나 지적재산권을 침해하는 등 '회사'나 제3자의 권리를 침해하는 행위</li>
        <li>다른 '회원'의 ID 및 비밀번호를 도용하여 부당하게 서비스를 이용한 경우</li>
        <li>정크메일(junk mail), 스팸메일(spam mail), 행운의 편지(chain letters), 피라미드 조직에 가입할 것을 권유하는 메일, 외설 또는 폭력적인 메시지ㆍ화상ㆍ음성 등 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 등)를 전송하거나 게시하는 행위</li>
        <li>'회사'의 직원이나 서비스의 관리자를 가장하거나 사칭하거나 타인의 명의를 도용하여 글을 게시하거나 메시지를 발송하는 행위</li>
        <li>스토킹(stalking) 등 다른 '회원'의 서비스 이용을 방해하는 행위</li>
        <li>다른 '회원'의 개인정보를 그 동이 없이 수집, 저장, 공개하는 행위</li>
        <li>불특정 다수의 '회원'을 대상으로 하여 광고 또는 선전을 게시하거나 스팸 메일을 전송하는 등 현행법령, '회사'가 제공하는 서비스에 대한 약관 및 기타 서비스 이용에 관한 규정을 위반하는 행위</li>
        <li>리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를 복제, 분해 또는 모방 기타 변형하는 행위</li>
        <li>자동접속프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를 이용하여 '회사'의 서버에 부하를 일으켜 정상적인 서비스를 방해하는 행위</li>
        <li>본인 아닌 제 3자에게 접속권한을 부여하는 행위</li>
        <li>'회사'의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</li>
        <li>기타 불법적이거나 부당한 행위</li>
      </ol>
      <p>
        ② '회사'는 '회원'이 위 항의 행위를 하는 경우 서비스의 이용을 제한하거나 일방적으로 본 계약을 해지할 수 있습니다.
      </p>
      <h2>제14조(양도금지)</h2>
      <p>
        본 이용약관을 통한 서비스를 받을 권리에 대하여 이를 양도 내지 증여하거나 질권의 목적으로 할 수 없습니다.
      </p>

      <h2>제15조(이용계약의 해지)</h2>
      <p>
        '회원'은 언제든지 '회사'가 정하는 절차에 따라 이용계약 해지 신청을 할 수 있으며, '회사'는 관련 법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
      </p>
      <p>
        '회원'이 계약을 해지할 경우 관련 법 및 '회사'가 별도로 정하는 "개인정보취급방침"에 따라 '회사'가 '회원'의 개인정보 등을 보유하는 경우를 제외하고는 즉시 해지처리 합니다.
      </p>
      <p>
        '회원'이 계약을 해지하는 경우, '회원'이 작성한 게시물 중 프로필 등과 같이 본인 계정에 등록된 게시물 일체는 삭제됩니다. 또한, 다른 '회원'에 의해 담기, 스크랩 된 자료는 삭제됩니다.
      </p>
      <p>
        '회원'이 제13조('회원'의 의무)의 규정을 위반한 경우 '회사'는 사전통보 없이 일방적으로 본 계약을 해지할 수 있고, 이로 인하여 서비스 운영에 손해가 발생한 경우 이에 대한 민ㆍ형사상 책임도 물을 수 있습니다.
      </p>
      <h2>제16조(이용제한 등)</h2>
      <p>
        ① '회사'는 '회원'이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 '서비스' 이용을 단계적으로 제한할 수 있습니다.
      </p>
      <p>
        ② '회사'는 전항에도 불구하고, 주민등록법을 위반한 명의도용 및 결제도용, 전화번호 도용, 저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지시 서비스 이용을 통해 획득한 혜택 등도 모두 소멸되며, '회사'는 이에 대해 별도로 보상하지 않습니다.
      </p>
      <p>
        ③ '회사'는 '회원'이 계속해서 3개월 이상 로그인하지 않는 경우, '회원'정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.
      </p>
      <p>
        ④ 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 '회사'의 이용제한 정책에서 정하는 바에 의합니다.
      </p>
      <p>
        ⑤ 본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 '회사'는 제11조['회원'에 대한 통지]에 따라 통지합니다.
      </p>
      <p>
        ⑥ '회원'은 본 조에 따른 이용제한 등에 대해 '회사'가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 '회사'가 인정하는 경우 '회사'는 즉시 서비스의 이용을 재개합니다.
      </p>
      <h2>제17조(면책)</h2>
      <p>
        '회사'는 다음 사유로 서비스를 제공할 수 없는 경우 이로 인하여 '회원'에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
      </p>
      <ul>
        <li>- 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</li>
        <li>- 서비스 제공을 위하여 '회사'와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우 (단, 서비스 방해를 제공한 제3자의 행위가 본 이용약관에 저촉될 경우 서비스 방해를 제한할 수 있습니다)</li>
        <li>- '회원'의 귀책사유로 서비스 이용에 장애가 있는 경우</li>
        <li>- 기타 '회사'의 고의 또는 과실이 없는 사유로 인한 경우</li>
      </ul>

      <h2>제18조(상품판매 이벤트의 이용)</h2>
      <p>
        '회원'은 서비스 내 제휴된 다양한 이벤트를 유료, 무료로 이용하실 수 있습니다.
      </p>
      <p>
        제휴 이벤트 이용과 관련한 분쟁에서는 명시적인 책임소재가 제휴 '회사' 측에 있을 경우 '회사'는 책임을 지지 않습니다. 단 '회사'에서 직접 진행한 이벤트의 분쟁 발생 시 책임 소재가 '회사'에 있을 경우는 '회사'가 책임을 집니다.
      </p>
      <p>
        ‘회사’는 상품판매와 직접적인 관련이 없으며, 모든 상거래의 책임은 ‘회원’과 자료제공사 (또는 글쓴이) 에게 있습니다. 이에 대해 ‘회사’는 일체의 책임을 지지 않습니다.
      </p>
      <p>
        ‘회사’에 등록된 모든 광고와 저작권 및 법적책임은 자료제공사 (또는 글쓴이) 에게 있으므로 ‘회사’는 광고에 대한 책임을 지지 않습니다.
      </p>

      <h2>제19조('회사'의 의무)</h2>
      <p>
        '회사'는 관련 법령 또는 이 약관을 위반하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다합니다.
      </p>
      <p>
        '회사'는 신용정보를 포함한 '회원'의 개인정보 보호를 위하여 보안시스템을 갖추어야 하며 개인정보 취급방침을 공시하고 준수하겠습니다.
      </p>
      <p>
        '회사'는 서비스 이용과 관련한 '회원'의 의견이나 불만사항 등이 정당하다고 인정될 경우 이를 처리하여야 하며, 처리된 결과는 게시판 또는 이메일을 통해 '회원'에게 통보합니다.
      </p>
      <h2>제20조(서비스의 제공 등)</h2>
      <p>
        '회사'는 '회원'에게 아래와 같은 서비스를 제공합니다.
      </p>
      <ol>
        <li>소셜 네트워크 서비스(SNS)</li>
        <li>맞춤 정보 제공 서비스</li>
        <li>위치정보 서비스</li>
        <li>이벤트 및 전시회 서비스</li>
        <li>EM, DM, TM, 방문을 통한 통신판매 및 금융상품 안내 서비스</li>
        <li>기타 '회사'가 추가 개발하거나 다른 '회사'와의 제휴계약 등을 통해 '회원'에게 제공하는 일체의 서비스</li>
      </ol>

      <p>
        '회사'는 서비스를 일정 범위로 분할하여 각 범위 별로 이용 가능 시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.
      </p>

      <p>
        서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
      </p>

      <p>
        '회사'는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 '회사'는 제12조['회원'에 대한 통지]에 정한 방법으로 '회원'에게 통지합니다. 다만 '회사'가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
      </p>

      <p>
        '회사'는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검 시간은 서비스 제공 화면에 공지한 바에 따릅니다.
      </p>
      <h2>제21조(서비스의 변경)</h2>
      <p>
        '회사'는 이용 감소로 인한 원활한 서비스 제공의 곤란 및 수익성 악화, 기술 진보에 따른 차세대 서비스로의 전환 필요성, 서비스 제공과 관련한 '회사' 정책의 변경 등 기타 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 '서비스'를 변경 또는 중단할 수 있습니다.
      </p>
      <p>
        '서비스의' 내용, 이용방법, 이용시간에 대하여 변경 또는 서비스 중단이 있는 경우에는 변경 또는 중단될 서비스의 내용 및 사유와 일자 등은 그 변경 또는 중단 전에 '회사' 앱 또는 서비스 내 공지사항 화면, 기타 럽맘 메시지 등 '회원'이 충분이 인지할 수 있는 방법으로 30일의 기간을 두고 사전에 공지합니다.
      </p>
      <h2>제22조(서비스의 변경)</h2>
      <p>
        '회사'는 서비스의 운영과 관련하여 럽맘 서비스 화면, 럽맘 메시지, 앱 공지사항 등에 광고를 게재할 수 있습니다.
      </p>
      <h2>제23조(게시물의 저작권)</h2>
      <p>
        '회원'이 서비스 내에 게시한 게시물의 저작권은 럽맘에게 귀속됩니다. '회사'는 '회원'의 게시물을 사전 동의 없이 사용할 수 있습니다.
      </p>
      <h2>제24조(게시물의 관리)</h2>
      <p>
        ① '회원'의 게시물이 정보통신망 법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시 중단 및 삭제 등을 요청할 수 있으며, '회사'는 관련 법에 따라 조치를 취하여야 합니다.
      </p>
      <p>
        ② '회사'는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 '회사' 정책 및 관련법에 위반되는 경우에는 관련 법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.
      </p>
      <p>
        ③ 본 조에 따른 세부절차는 정보통신망 법 및 저작권법이 규정한 범위 내에서 '회사'가 정한 게시중단요청서비스에 따릅니다. 게시중단 요청 서비스: https://luvmom.kr
      </p>
      <h2>제25조(위치정보의 이용)</h2>
      <p>
        ① '회사'는 '회원'이 본 약관에 동의한 후 모바일 단말기에서 본 서비스에 접속하여 최초 1회 위치정보이용에 동의하는 경우 해당 '회원'의 위치정보를 이용합니다.
      </p>
      <p>
        1) '회사'명: 주식회사 ㈜럽맘<br />
        2) 메일: sarangmam2020@gmail.com
      </p>
      <p>
        ② 위치정보이용 동의<br />
        1) GPS가 탑재된 모바일 단말기로 본 서비스에 접속하여 최초 1회 위치정보이용에 동의한 경우에만 위치정보를 이용합니다. 동의하지 않은 이용자의 경우 모바일 단말기에서 서비스 이용에 제한이 있을 수 있습니다.<br />
        2) '회원'은 위치정보이용에 대한 동의 여부를 설정 항목에서 선택할 수 있습니다.<br />
        3) 모바일 단말기로 무선 인터넷을 통해 본 서비스에 접속한 후 최초 1회 위치정보이용에 동의한 경우 WPS(WiFi Positioning System) 기술을 통해 수집된 위치정보를 이용합니다.<br />
        4) '회사'는 이용자가 위치정보이용에 동의하지 않은 경우에는 위치정보를 이용하지 않습니다.
      </p>
      <h2>제26조(개인 위치정보 주체의 권리)</h2>
      <p>
        ① 개인위치정보주체는 개인위치정보의 이용, 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다.
      </p>
      <p>
        ② 개인위치정보주체는 언제든지 개인위치정보의 이용 또는 제공의 일시적인 중지를 요구할 수 있습니다. 이 경우 '회사'는 요구를 거절하지 아니하며, 이를 위한 기술적 수단을 갖추고 있습니다.
      </p>
      <p>
        ③ 개인위치정보주체는 '회사'에 대하여 아래 자료의 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 '회사'는 정당한 이유 없이 요구를 거절하지 아니합니다.
        <br />
        1) 개인위치정보주체에 대한 위치정보 이용, 제공사실 확인자료
        <br />
        2) 개인위치정보주체의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법령의 규정에 의하여 제3자에게 제공된 이유 및 내용
      </p>
      <p>
        ④ '회사'는 개인위치정보주체가 동의의 전부 또는 일부를 철회한 경우에는 지체 없이 수집된 개인위치정보 및 위치정보이용제공사실 확인자료(동의의 일부를 철회하는 경우에는 철회하는 부분의 개인위치정보 및 위치정보 이용제공사실 확인자료에 한합니다)를 파기합니다.
      </p>
      <p>
        ⑤ 개인위치정보주체는 제1항 내지 제3항의 권리행사를 위하여 본 약관 제24조의 연락처를 이용하여 '회사'에 요구할 수 있습니다.
      </p>
      <h2>제27조(위치정보 이용 제공사실 확인자료 보유근거 및 보유기간)</h2>
      <p>
        ① '회사'는 위치정보의 보유 및 이용 등에 관한 법률 제16조 제2항에 근거하여 개인위치정보주체에 대한 위치정보 이용, 제공사실 확인자료를 위치정보시스템에 자동으로 기록, 보존하며 해당 자료는 1년간 보관합니다.
      </p>
      <p>
        ② '회원'이 본 서비스를 해지할 경우 '회사'는 '회원'의 위치정보를 즉시 파기합니다.
      </p>
      <h2>제28조(개인위치정보 제3자 제공 시 즉시 통보)</h2>
      <p>
        ① '회사'는 개인위치정보주체의 동의 없이 당해 개인위치정보주체의 개인위치정보를 제3자에게 제공하지 아니하며, 제3자 제공 서비스를 제공해야 할 경우 제공 받는 자 및 제공목적을 사전에 개인위치정보주체에게 고지하고 동의를 받습니다.
      </p>
      <p>
        ② '회사'는 개인위치정보를 개인위치정보주체가 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신단말장치로 매회 개인위치정보주체에게 제공받는 자, 제공 일시 및 제공목적을 즉시 통보합니다.
      </p>
      <p>
        ③ 만, 아래에 해당하는 경우에는 개인위치정보주체가 미리 특정하여 지정한 통신단말장치 또는 전자우편주소 등으로 통보합니다.
      </p>
      <p>
        1) 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우
      </p>
      <p>
        2) 개인위치정보주체가 개인위치정보를 수집한 당해 통신단말장치 외의 통신단말장치 또는 전자우편주소 등으로 통보할 것을 미리 요청한 경우
      </p>
      <h2>제29조(서비스의 종료)</h2>
      <p>
        '회사'는 서비스를 종료하고자 하는 날로부터 3개월 이전에 '회원'에게 알립니다.
        종료 통지일로 부터 서비스 종료일까지 일부 서비스 이용이 제한 될 수 있습니다.
      </p>
      <h2>제30조(준거법 및 합의 관할)</h2>
      <p>
        '회사'와 '회원'간에 제기된 법적 분쟁은 대한민국 법을 준거법으로 합니다.
        '회사'와 '회원'간의 분쟁에 관한 소송은 민사소송법상의 주소지를 관할하는 관할법원에 제소합니다.
      </p>
      <h2>제31조(개인정보보호의무)</h2>
      <p>
        '회사'는 관련 법령이 정하는 바에 따라서 '회원' 등록정보를 포함한 개인정보를 보호하기 위하여 노력합니다.
        이에 관해서는 관련 법령 및 '회사'의 "개인정보 취급방침"에 의하며, '회원'이 늘 확인 하실 수 있도록 웹사이트를 통해 공시합니다.
      </p>
    </div>
          </div>

        </main>
      </div>
      
     <Footer city={city} setCity={setCity} />
      </>
    );
}

export default UsedRules;