// PopUp.js

import React from 'react';
import WhereSelect from '../../components/Member/WhereSelect2.js';
function PopUp({isPopupOpen,setPopupOpen,selectedCity,setSelectedCity,setCity}) {
  // 팝업 내용을 구성
  return (
    <div className="main_popup">
      {/* 팝업 내용 */}
      <WhereSelect
      isPopupOpen={isPopupOpen} 
      setPopupOpen={setPopupOpen}
      selectedCity={selectedCity}
      setSelectedCity={setSelectedCity}
      setCity={setCity}
      />
    </div>
  );
}

export default PopUp;
